import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";
import { BsExclamationCircle, BsArrowLeftShort } from "react-icons/bs";
import api from '../../services/api';
import { Div, Text, Container, Row, Col, Input, Button, Icon, Notification, Tag } from "atomize";

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import AuthContext from '../../contexts/auth';
import LanguageContext from '../../contexts/language';

export default function Forgot() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [pin, setPin] = useState('');
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    function setViewPassword(){
        showPassword ? setShowPassword(false) : setShowPassword(true);
    }

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    
    const { signIn } = useContext(AuthContext);

    const [page, setPage] = useState('forgot');

    async function submitForgot(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(email==""){
                setLoading(false);
                setError(true);
                setErrorMessage(language.no_data);
                return;
            }

            const response = await api.post('/forgot', {email, lang});

            if(response.data.status==='success'){
                setLoading(false);
                setError(false);
                setErrorMessage('');
                setPage('pin');
                return;
            }else if(response.data.status==='error'){
                setLoading(false);
                setError(true);
                setErrorMessage(language.forgot_not_found);
                return;
            }

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error);

        }
    }

    async function submitPin(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(pin==""){
                setLoading(false);
                setError(true);
                setErrorMessage(language.forgot_error_pin);
                return;
            }

            const response = await api.post('/forgot-token', {email, pin});

            if(response.data.status==='success'){
                setLoading(false);
                setError(false);
                setErrorMessage('');
                setPage('new_password');
            }else if(response.data.status==='error'){
                setLoading(false);
                setError(true);
                setErrorMessage(language.forgot_error_pin);
            }

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error);

        }
    }

    async function submitPassword(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(password==""){
                setLoading(false);
                setError(true);
                setErrorMessage(language.forgot_error_pin);
                return;
            }

            const response = await api.post('/forgot-password', {email, pin, password});

            if(response.data.status==='success'){
                setLoading(false);
                setError(false);
                setErrorMessage('');
                //ir para Login
                history.push('/login');
            }else if(response.data.status==='error'){
                setLoading(false);
                setError(true);
                setErrorMessage(language.error);
            }

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error);
        }
    }

    return (
        <div className="custom-page">
            <Header />
            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Div bg="gray300" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col p="30px" size={{ md: 4 }} bg="white" shadow="3" rounded="lg" className="m-auto" textAlign="center">
                            {page==='forgot' &&
                            <form onSubmit={submitForgot}>
                                <Div p={{ t: '10px', b: '30px' }}>
                                    <Text p={{ b: '10px' }} textSize="title" textColor="black800">
                                        {language.forgot_reset}
                                    </Text>
                                </Div>
                                <Div p={{ b: '30px' }}>
                                    <Input
                                        placeholder={language.form_email}
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        foucsBorderColor="info600"
                                        rounded="lg"
                                        m={{ b: '10px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                        suffix={
                                        <Icon
                                            name="Email"
                                            color="gray900"
                                            size="16px"
                                            pos="absolute"
                                            top="50%"
                                            right="1rem"
                                            transform="translateY(-50%)"
                                        />
                                        }
                                    />
                                </Div>
                                <Button
                                    type="submit"
                                    w="100%"
                                    h="44px"
                                    textWeight="600"
                                    textSize="body"
                                    textColor="info700"
                                    hoverTextColor="info900"
                                    bg="info200"
                                    hoverBg="info300"
                                    rounded="lg"
                                    disabled={loading}
                                >
                                    {
                                    loading ?
                                    <><Icon
                                        name="Loading"
                                        size="22px"
                                        color="info700"
                                        m={{r: '6px'}}
                                    /> {language.forgot_search}</>:
                                    <>{language.forgot_send}</>
                                    }
                                </Button>
                                <Div p={{t: '40px'}} textSize="caption" textAlign="center">
                                    <Link to="/login" className="b-link"><BsArrowLeftShort size={24} /> {language.forgot_back}</Link>
                                </Div>
                            </form>
                            }

                            {page==='pin' &&
                            <form onSubmit={submitPin}>
                                <Div p={{ t: '10px', b: '30px' }}>
                                    <Text p={{ b: '10px' }} textSize="title" textColor="black800">
                                        {language.forgot_reset}
                                    </Text>
                                    {!error &&
                                    <Tag
                                        bg={`info100`}
                                        border="1px solid"
                                        borderColor={`info500`}
                                        textColor={`info800`}
                                        textSize="paragraph"
                                        border="0"
                                        m={{ r: "0.5rem", b: "0.5rem" }}
                                    >
                                        {language.forgot_send_pin}
                                    </Tag>
                                    }
                                </Div>
                                <Div p={{ b: '30px' }}>
                                    <InputMask className="custom-input" mask="999 999" placeholder="000 000" value={pin} onChange={e => setPin(e.target.value)} type="tel"  />
                                </Div>
                                <Button
                                    type="submit"
                                    w="100%"
                                    h="44px"
                                    textWeight="600"
                                    textSize="body"
                                    textColor="info700"
                                    hoverTextColor="info900"
                                    bg="info200"
                                    hoverBg="info300"
                                    rounded="lg"
                                    disabled={loading}
                                >
                                    {
                                    loading ?
                                    <><Icon
                                        name="Loading"
                                        size="22px"
                                        color="info700"
                                        m={{r: '6px'}}
                                    /> {language.forgot_search}</>:
                                    <>{language.forgot_send}</>
                                    }
                                </Button>
                                <Div p={{t: '40px'}} textSize="caption" textAlign="center">
                                    <Link to="/login" className="b-link"><BsArrowLeftShort size={24} /> {language.forgot_back}</Link>
                                </Div>
                            </form>
                            }
                            
                            {page==='new_password' &&
                            <form onSubmit={submitPassword}>
                                <Div p={{ t: '10px', b: '30px' }}>
                                    <Text p={{ b: '10px' }} textSize="title" textColor="black800">
                                        {language.forgot_reset}
                                    </Text>
                                </Div>
                                <Div p={{ b: '30px' }}>
                                    <Input
                                        placeholder={language.password}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        foucsBorderColor="info600"
                                        rounded="lg"
                                        type={showPassword ? "text" : "password"}
                                        m={{ b: '20px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                        suffix={
                                        <Button
                                            type="button"
                                            pos="absolute"
                                            onClick={setViewPassword}
                                            bg="transparent"
                                            w="3rem"
                                            top="0"
                                            right="0"
                                        >
                                            <Icon
                                            name={showPassword ? "EyeSolid" : "Eye"}
                                            color="gray900"
                                            size="16px"
                                            pos="absolute"
                                            top="50%"
                                            right="1rem"
                                            transform="translateY(-50%)"
                                            />
                                        </Button>
                                        }
                                    />
                                </Div>
                                <Button
                                    type="submit"
                                    w="100%"
                                    h="44px"
                                    textWeight="600"
                                    textSize="body"
                                    textColor="info700"
                                    hoverTextColor="info900"
                                    bg="info200"
                                    hoverBg="info300"
                                    rounded="lg"
                                    disabled={loading}
                                >
                                    {
                                    loading ?
                                    <><Icon
                                        name="Loading"
                                        size="22px"
                                        color="info700"
                                        m={{r: '6px'}}
                                    /> {language.form_loading}</>:
                                    <>{language.forgot_reset}</>
                                    }
                                </Button>
                                <Div p={{t: '40px'}} textSize="caption" textAlign="center">
                                    <Link to="/login" className="b-link"><BsArrowLeftShort size={24} /> {language.forgot_back}</Link>
                                </Div>
                            </form>
                            }
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </div>
    );
}