import React, { useContext, useState } from 'react';
import { BsExclamationCircle, BsCheckCircle } from "react-icons/bs";
import InputMask from "react-input-mask";
import { HiChevronDown } from 'react-icons/hi';
import api from '../../services/api';
import { Div, Text, Container, Row, Col, Input, Button, Icon, Notification, Textarea } from "atomize";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import flag_brazil from '../../assets/images/flag-brazil.png';
import flag_usa from '../../assets/images/flag-usa.png';

import LanguageContext from '../../contexts/language';

export default function Contact() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [phoneLang, setPhoneLang] = useState(lang);

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);

    async function submitContact(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(name==="" || email==="" || phone==="" || message===""){
                setLoading(false);
                setError(true);
                setErrorMessage(language.no_data);
                return;
            }

            const response = await api.post('/contact', {
                name,
                email,
                phone,
                message,
            });

            setError(false);
            setLoading(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error);
        }
    }

    return (
        <div className="custom-page">
            <Header />
            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Notification
                bg="success700"
                isOpen={success}
                onClose={() => setSuccess(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {language.form_success}
            </Notification>
            <Div bg="gray300" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col p="30px" size={{ md: 4 }} bg="white" shadow="3" rounded="lg" className="m-auto" textAlign="center">
                            <form onSubmit={submitContact}>
                                <Div p={{ t: '10px', b: '30px' }}>
                                    <Text p={{ b: '10px' }} textSize="title" textColor="black800">
                                        {language.menu_contact}
                                    </Text>
                                </Div>
                                <Div p={{ b: '30px' }}>
                                    <Input
                                        placeholder={language.form_name}
                                        type="text"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        focusBorderColor="info600"
                                        rounded="lg"
                                        m={{ b: '20px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                    />

                                    <Input
                                        placeholder={language.form_email}
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        focusBorderColor="info600"
                                        rounded="lg"
                                        m={{ b: '20px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                    />

                                    <Div pos="relative">
                                        {
                                        phoneLang==='pt' ?
                                        <InputMask className="custom-input" mask="(99) 99999-9999" placeholder={language.form_whatsapp} value={phone} onChange={e => setPhone(e.target.value)} type="tel" />:
                                        <InputMask className="custom-input" mask="(999) 999-9999" placeholder={language.form_whatsapp} value={phone} onChange={e => setPhone(e.target.value)} type="tel" />
                                        }
                                        <div className="lang-content v-phone">
                                            <a onClick={() =>{}} className="lang-menu">
                                                <div><img src={phoneLang==='pt' ? flag_brazil : flag_usa} /></div>
                                                <div className="lang-arrow"><HiChevronDown size={14} /></div>
                                            </a>
                                            <ul className="lang-option">
                                                <li>
                                                    <a onClick={() => {setPhoneLang('pt')}}>
                                                        <img src={flag_brazil} />{language.phone_pt}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={() => {setPhoneLang('en')}}>
                                                        <img src={flag_usa} />{language.phone_en}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </Div>
                                    
                                    <Textarea 
                                        placeholder={language.form_message}
                                        value={message}
                                        onChange={e => setMessage(e.target.value)}
                                        focusBorderColor="info600"
                                        rounded="lg"
                                        m={{ t: '20px', b: '20px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                    />

                                </Div>

                                <Button
                                    type="submit"
                                    w="100%"
                                    h="44px"
                                    textWeight="600"
                                    textSize="body"
                                    textColor="info700"
                                    hoverTextColor="info900"
                                    bg="info200"
                                    hoverBg="info300"
                                    rounded="lg"
                                    disabled={loading}
                                >
                                    {
                                    loading ?
                                    <><Icon
                                        name="Loading"
                                        size="22px"
                                        color="info700"
                                        m={{r: '6px'}}
                                    /> {language.form_sending}</>:
                                    <>{language.form_send}</>
                                    }
                                </Button>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </div>
        
    );
}