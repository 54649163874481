import React, { useContext} from 'react';
import 'video-react/dist/video-react.css';
import { Div, Text, Container, Row, Col } from "atomize";

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import LanguageContext from '../../contexts/language';

export default function PageTutorial() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '40px' }} textSize="display2" textColor="black800">
                                {language.tutorial}
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col p="20px" size={{ md: 12 }} rounded="6px" className="m-auto" textAlign="justify">
                            
                            <div className="content-video">
                                <div className="embed-container">
                                    <iframe src={`https://www.youtube.com/embed/${lang=='pt' ? '_834MGR9JZY' : 'Wj2NFj-iip8'}?rel=0&showinfo=0&autoplay=1`} frameborder="0" allowfullscreen></iframe>
                                </div>
                            </div>
                              
                        </Col>

                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}