import React, { useContext, useState, useEffect } from 'react';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Events from '../../components/Events';
import ShimmerEvents from '../../components/ShimmerEvents';

import LanguageContext from '../../contexts/language';

export default function PageEvents() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    async function loadPage(){
 
        const response = await api.post(`/events?lang=${lang}`);
        setEvents(response.data.content.events ? response.data.content.events: []);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language]);

    return (
        <div className="content-app">
            <Header />
            {loading ?
            <ShimmerEvents />:
            <Events content={events} />
            }
            <Footer />
        </div>
    );
}
