import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image } from "atomize";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';

import LanguageContext from '../../contexts/language';

export default function PageText() {

    const { id } = useParams();
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');
    
    const [page, setPage] = useState([]);
    const [loading, setLoading] = useState(true);
    
    async function loadPage(){
        const response = await api.post(`/page?id=${id}&lang=${lang}`);
        setPage(response.data.content.page[0].post_items ? response.data.content.page[0].post_items : []);
        setLoading(false);
    }
    
    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language, id]);

    function titles(title){
        switch (title) {
            case 'schedule': return language.menu_schedule;
            case 'terms': return language.menu_terms;
            case 'about': return language.menu_about;
            case 'privacy': return language.menu_privacy;
            default: return (<span></span>);
        }
    }

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '60px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '40px' }} textSize="display2" textColor="black800">
                                {
                                loading ?
                                <Shimmer width={300} height={30} />:
                                <>{titles(id)}</>
                                }
                                </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col p="20px" size={{ md: 8 }} className="m-auto" textAlign="justify">
                            {
                                loading ?
                                <>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={350} />
                                        <Shimmer />
                                        <Shimmer width={100} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                        <Shimmer />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer width={300} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={400} />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={400} />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                    </p>
                                </>:
                                <>
                                    {page.map((item, index) => (
                                    <Div className="content-post" textSize="paragraph" textColor="gray900">
                                        {
                                        item.type==='image' &&
                                            <div className="content-image" key={`image-${index}`}>
                                                <Row>
                                                    <Col size={{ md: 12 }} className="m-auto" textAlign="justify">
                                                        <img src={item.image} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        {
                                        item.type==='text' &&
                                            <div className="content-text" key={`text-${index}`} dangerouslySetInnerHTML={{__html: item.text}} />
                                        }
                                        {
                                        item.type==='video' &&
                                            <div className="content-video" key={`video-${index}`}>
                                                <div className="embed-container">
                                                    {
                                                    item.video_type==='vimeo' &&
                                                    <iframe src={`https://player.vimeo.com/video/${item.video}?title=0&byline=0&portrait=0`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                    {
                                                    item.video_type==='youtube' &&
                                                    <iframe src={`https://www.youtube.com/embed/${item.video}?rel=0&showinfo=0`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                    {
                                                    item.video_type==='facebook' &&
                                                    <iframe src={`https://www.facebook.com/plugins/video.php?show_text=false&href=${item.video}`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Div>
                                    ))}
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}