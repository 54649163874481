import React, { useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Icon } from "atomize";

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import LanguageContext from '../../contexts/language';

export default function PaymentProcess() {

    const { id } = useParams();
    const { language } = useContext(LanguageContext);

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '20px' }} textSize="display2" textColor="black800">{language.payment_message_1_1}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col p="20px" size={{ md: 8 }} d="flex" flexDir="column" align="center" justify="center" className="m-auto" textAlign="center">
                            <Div d="flex" align="center" justify="center" rounded="circle" bg="warning400" w="64px" h="64px">
                                <Icon name="Info" color="warning700" size="32px" />
                            </Div>
                            
                            <Text textSize="paragraph" textColor="gray800" p={{t: '20px'}}>
                                {language.payment_message_2_1}<br />
                                {language.payment_message_3}
                            </Text>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col size={{ md: 8 }} p={{ t: "40px", b: "40px"}} className="m-auto" textAlign="center">
                            <Link to={`/purchase/${id}`} className="btn_all">
                                {language.payment_message_4}
                            </Link>
                        </Col>
                    </Row>
                   
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}