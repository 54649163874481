import React, { useContext } from 'react';
import { Div, Text, Container, Row, Col, Image } from "atomize";
import { Link } from 'react-router-dom';

import imgTop from '../assets/images/bg.jpg';
import imgBottom from '../assets/images/bottom.jpg';
import LanguageContext from '../contexts/language';
import Sponsors from '../components/Sponsors';

import logo from '../assets/images/logo_black.png';
import logo_nerdetcetera from '../assets/images/logo_nerdetcetera_text.png';

export default function Footer() {

    const { language } = useContext(LanguageContext);
    
    return (
        <>
        <Div bg="white" p={{b: '50px'}}>
            <Sponsors />
            <div className="container-fluid remove-mt">
                <div className="row">
                    <img className="img-bottom mobile-hidden" alt="bottom" src={imgBottom} />
                    <img className="img-bottom mobile-view" alt="bottom" src={imgTop} />
                </div>
            </div>
            <Container>
                <Row p={{t: '40px'}} className="mobile-hidden">
                    <Col size={{ xs: 12, md: 4 }}>
                        <Div className="footer-link" p={{t:'20px'}} d="flex" flexDir="column" textAlign="left">
                            <Link to="/page/about">{language.menu_about}</Link>
                            <Link to="/welcome">{language.menu_welcome}</Link>
                            <Link to="/page/schedule">{language.menu_schedule}</Link>
                        </Div>
                    </Col>
                    <Col size={{ xs: 12, md: 4 }}>
                        <Div className="footer-link" p={{t:'20px'}} d="flex" flexDir="column" textAlign="center">
                            <Link to="/events">{language.title_event}</Link>
                            <Link to="/authors">{language.menu_author}</Link>
                            <Link to="/books">{language.menu_books}</Link>
                        </Div>
                    </Col>
                    <Col size={{ xs: 12, md: 4 }}>
                        <Div className="footer-link" p={{t:'20px'}} d="flex" flexDir="column" textAlign="right">
                            <Link to="/account">{language.menu_account}</Link>
                            <Link to="/contact">{language.menu_contact}</Link>
                            <Link to="/page/terms">{language.menu_terms} & {language.menu_privacy}</Link>
                        </Div>
                    </Col>
                </Row>
                <Row p={{t: '40px'}} className="mobile-view-d">
                    <Col size={{ xs: 12, md: 4 }}>
                        <Div className="footer-link" p={{t:'20px'}} d="flex" flexDir="column" align="center" justify="center">
                            <Link to="/page/about">{language.menu_about}</Link>
                            <Link to="/welcome">{language.menu_welcome}</Link>
                            <Link to="/page/schedule">{language.menu_schedule}</Link>
                        </Div>
                    </Col>
                    <Col size={{ xs: 12, md: 4 }}>
                        <Div className="footer-link" p={{t:'20px'}} d="flex" flexDir="column" align="center" justify="center">
                            <Link to="/events">{language.title_event}</Link>
                            <Link to="/authors">{language.menu_author}</Link>
                            <Link to="/books">{language.menu_books}</Link>
                        </Div>
                    </Col>
                    <Col size={{ xs: 12, md: 4 }}>
                        <Div className="footer-link" p={{t:'20px'}} d="flex" flexDir="column" align="center" justify="center">
                            <Link to="/account">{language.menu_account}</Link>
                            <Link to="/contact">{language.menu_contact}</Link>
                            <Link to="/page/terms">{language.menu_terms} & {language.menu_privacy}</Link>
                        </Div>
                    </Col>
                </Row>
            </Container>
        </Div>
        <Div bg="gray200" p={{t: '30px', b: '30px'}}>
            <Container>
                <Row className="mobile-hidden">
                    <Col size={{ xs: 12, md: 6 }} textAlign="left">
                        <Image w="150px" p={{t: "20px"}} src={logo} />
                        <p className="mt-3 mb-3 footer-text">©2020, CCBU - FLI Miami. {language.copyright}</p>
                    </Col>
                    <Col size={{ xs: 12, md: 6 }} textAlign="right" p={{t: "50px"}}>
                        <p className="mt-3 mb-3 footer-text">{language.developed} <a href="http://nerdetcetera.com" target="_blank"><Image w="130px" p={{l: "4px"}} src={logo_nerdetcetera} /></a></p>
                    </Col>
                </Row>
                <Row className="mobile-view-d">
                    <Col size={{ xs: 12, md: 6 }} textAlign="center">
                        <Image w="150px" p={{t: "20px"}} src={logo} />
                        <p className="mt-3 mb-3 footer-text">©2020, CCBU - FLI Miami. {language.copyright}</p>
                    </Col>
                    <Col size={{ xs: 12, md: 6 }} textAlign="center" p={{t: "50px"}}>
                        <p className="mt-3 mb-3 footer-text">{language.developed} <a href="http://nerdetcetera.com" target="_blank"><Image w="130px" p={{l: "4px"}} src={logo_nerdetcetera} /></a></p>
                    </Col>
                </Row>
            </Container>
        </Div>
        </>
    );
}