import React, { useContext } from 'react';
import { Div, Text, Container, Row, Col, Image } from "atomize";

import LanguageContext from '../contexts/language';

import s_logo_0 from '../assets/images/s_logo_0.png';
import s_logo_1 from '../assets/images/s_logo_1.png';
import s_logo_2 from '../assets/images/s_logo_2.png';
import s_logo_3 from '../assets/images/s_logo_3.png';

import s_logo_4 from '../assets/images/logo_colab.png';
import s_logo_5 from '../assets/images/logo_nerdetcetera.png';

export default function Sponsors() {

    const { language } = useContext(LanguageContext);
    
    return (
        <Div p={{ t: '50px', b: '210px' }} className="bg-footer">
            <div className="container-fluid">
                <Row className="mobile-hidden" flexDir="column" align="center" justify="center">
                    <Col size={{ md: 6 }} textAlign="center">
                        <Text textSize="subheader" textColor="white" textAlign="center">{language.sponsors_3}</Text>
                        <Div d="flex" h="180px" flexDir="column" align="center" justify="center">
                            <Image w="350px" src={s_logo_1} />
                        </Div>
                    </Col>
                    <Col size={{ md: 6 }} textAlign="center">
                        <Text textSize="subheader" textColor="white" textAlign="center">{language.sponsors_1}</Text>
                        <Div d="flex" h="180px" m={{t: '0px'}} flexDir="row" align="center" justify="center">
                            <Image w="250px" src={s_logo_0} p={{r: '20px'}} />
                            <Image w="200px" src={s_logo_3} p={{l: '20px'}} />
                        </Div>
                    </Col>
                    <Col size={{ md: 6 }} textAlign="center">
                        <Text textSize="subheader" textColor="white" textAlign="center">{language.sponsors_2}</Text>
                        <Div d="flex" h="180px" m={{t: '0px'}} flexDir="row" align="center" justify="center">
                            <Image w="110px" src={s_logo_2} m={{r: '45px'}} />
                            <Image w="40px" src={s_logo_4} m={{r: '45px'}} />
                            <Image w="60px" src={s_logo_5} />
                        </Div>
                    </Col>
                </Row>
                <Row className="mobile-view-d">
                    <Col size={{ md: 12 }} textAlign="center">
                        <Text textSize="subheader" textColor="white" textAlign="center">{language.sponsors_3}</Text>
                        <Div d="flex" h="140px" flexDir="column" align="center" justify="center">
                            <Image w="280px" src={s_logo_1} />
                        </Div>
                    </Col>
                    <Col size={{ md: 12 }} textAlign="center">
                        <Text textSize="subheader" textColor="white" textAlign="center">{language.sponsors_1}</Text>
                        <Div d="flex" h="140px" m={{t: '0px'}} flexDir="row" align="center" justify="center">
                            <Image w="210px" src={s_logo_0} m={{l: '-20px'}} />
                            <Image w="160px" src={s_logo_3} />
                        </Div>
                    </Col>
                    <Col size={{ md: 12 }} textAlign="center">
                        <Text textSize="subheader" textColor="white" textAlign="center">{language.sponsors_2}</Text>
                        <Div d="flex" h="140px" m={{t: '0px'}} flexDir="row" align="center" justify="center">
                            <Image w="120px" src={s_logo_2} m={{r: '30px'}} />
                            <Image w="50px" src={s_logo_4} m={{r: '30px'}} />
                            <Image w="70px" src={s_logo_5} />
                        </Div>
                    </Col>
                    
                </Row>
            </div>
        </Div>
    );
}