import React, { useContext, useState, useEffect } from 'react';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Authors from '../../components/Authors';
import ShimmerAuthors from '../../components/ShimmerAuthors';

import LanguageContext from '../../contexts/language';

export default function PageAuthors() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [authors, setAuthors] = useState([]);
    const [loading, setLoading] = useState(true);

    async function loadPage(){
 
        const response = await api.post(`/authors?lang=${lang}`);
        setAuthors(response.data.content.authors ? response.data.content.authors: []);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language]);

    return (
        <div className="content-app">
            <Header />
            {loading ?
            <ShimmerAuthors />:
            <>
                <Authors content={authors} />
            </>
            }
            <Footer />
        </div>
    );
}
