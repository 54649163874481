import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Button, Image } from "atomize";
import { FiShoppingCart } from "react-icons/fi";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Books from '../../components/Books';
import Shimmer from '../../components/Shimmer';

import BaseSquare from '../../assets/images/blank-600.png';
import BaseHD from '../../assets/images/base-hd.png';

import LanguageContext from '../../contexts/language';

export default function PageText() {

    const { id } = useParams();
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');
    
    const [author, setAuthor] = useState([]);
    const [books, setBooks] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    
    async function loadPage(){
        const response = await api.post(`/author?id=${id}&lang=${lang}`);
        setAuthor(response.data.content.author[0]);
        setBooks(response.data.content.author[0].books ? response.data.content.author[0].books: []);
        setEvents(response.data.content.author[0].events ? response.data.content.author[0].events : []);

        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language, id]);


    function ContentShimmer(){
        return (
            <Row>
                <Col size="6">
                    <Div>
                        <Shimmer source={BaseSquare} rounded={12} />
                    </Div>
                </Col>
                <Col size="6">
                    <Text textColor="black800" textSize="display1"><Shimmer width={200} height={34} /></Text>
                    <Text m={{t: '15px'}} textColor="black800" textSize="subheader"><Shimmer width={160} height={30} /></Text>
                    <Text m={{t: '8px'}} textColor="gray600" textSize="body">
                        <Shimmer width={210} />
                        <Shimmer width={140} />
                        <Shimmer width={250} />
                    </Text>
                    <Text m={{t: '15px'}} textColor="gray700" textSize="body">
                        <Shimmer width={200} />
                        <Shimmer width={220} />
                        <Shimmer width={160} />
                        <Shimmer width={210} />
                        <Shimmer width={140} />
                        <Shimmer width={250} />
                        <Shimmer width={170} />
                        <Shimmer width={210} />
                    </Text>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    {loading ? 
                    <ContentShimmer />:
                    <Row>
                        <Col size={{xs: 12, md: 6}}>
                            <Div>
                                <Image src={BaseSquare} shadow="3" rounded="12px" m={{b: '20px'}} className="cover-bg" style={{backgroundImage: `url(${author.image})`}} />
                            </Div>
                            <Div className="mobile-hidden">
                            {events.length>0 &&
                                <Text m={{t: '8px'}} textColor="gray600" textSize="body">
                                    <Text m={{b: '10px'}} textColor="black200" textSize={{ xs: "subheader", md: "title" }}>{language.participating}</Text>
                                    <Row>
                                    {events.map(item => (
                                    <Col size="12" p={{b: '20px'}} d="flex" flexDir="column">
                                        <Row>
                                            <Col size="7">
                                                <Link to={`/event/${item.url}`} className="no-underline menu-opacity">
                                                    <Image shadow="3" rounded="12px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Link to={`/event/${item.url}`} className="b-link">
                                            <Text tag="span" m={{ r: '10px' }} textSize="subheader">{item.title}</Text>
                                        </Link>
                                        <Text tag="span" textSize="subheader" textColor="gray600">{item.date}</Text>
                                    </Col>
                                    ))}
                                    </Row>
                                </Text>
                            }
                            </Div>
                        </Col>
                        <Col size={{xs: 12, md: 6}}>
                            <Text textColor="black800" textSize={{ xs: "heading", md: "display1" }}>{author.name}</Text>
                            <Text m={{t: '10px'}} textColor="gray900" textSize="body" textAlign="justify">{author.text}</Text>
                            <Div className="mobile-view-d">
                            {events.length>0 &&
                                <Text m={{t: '30px'}} textColor="gray600" textSize="body">
                                    <Text m={{b: '20px'}} textColor="black200" textSize={{ xs: "subheader", md: "title" }}>{language.participating}</Text>
                                    <Row>
                                    {events.map(item => (
                                    <Col size="12" p={{b: '20px'}} d="flex" flexDir="column">
                                        <Row>
                                            <Col size="12">
                                                <Link to={`/event/${item.url}`} className="no-underline menu-opacity">
                                                    <Image shadow="3" rounded="12px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Link to={`/event/${item.url}`} className="b-link">
                                            <Text tag="span" m={{ r: '10px' }} textSize="subheader">{item.title}</Text>
                                        </Link>
                                        <Text tag="span" textSize="subheader" textColor="gray600">{item.date}</Text>
                                    </Col>
                                    ))}
                                    </Row>
                                </Text>
                            }
                            </Div>
                        </Col>
                    </Row>
                    }
                    
                </Container>
            </Div>
            <Books content={books} isHome={true} />
            <Footer />
        </>
        
    );
}