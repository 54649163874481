import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Events from '../../components/Events';
import Authors from '../../components/Authors';
import Books from '../../components/Books';
import Results from '../../components/Results';

import ShimmerEvents from '../../components/ShimmerEvents';
import ShimmerAuthors from '../../components/ShimmerAuthors';
import ShimmerBooks from '../../components/ShimmerBooks';

import LanguageContext from '../../contexts/language';

export default function Home() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const [events, setEvents] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [books, setBooks] = useState([]);
    
    const [loading, setLoading] = useState(true);

    async function loadPage(){
 
        const response = await api.post(`/search?lang=${lang}&q=${query.get('q')}`);
        setEvents(response.data.content.events ? response.data.content.events: []);
        setAuthors(response.data.content.authors ? response.data.content.authors: []);
        setBooks(response.data.content.books ? response.data.content.books: []);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language, query.get('q')]);

    return (
        <div className="content-app">
            <Header isSearch={true} />
            {loading ?
            <>
                <ShimmerEvents />
                <ShimmerAuthors />
                <ShimmerBooks />
            </>:
            <>
                <Results />
                <Events isSearch={true} content={events} />
                <Authors isSearch={true} content={authors} />
                <Books isSearch={true} content={books} />
            </>
            }
            <Footer />
        </div>
    );
}
