import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider as StyletronProvider, DebugEngine } from "styletron-react";
import { Client as Styletron } from "styletron-engine-atomic";

const engine = new Styletron();

ReactDOM.render(
    <React.StrictMode>
        <StyletronProvider value={engine}>
            <App />
        </StyletronProvider>
    </React.StrictMode>,
    document.getElementById('root')
);