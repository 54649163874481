import React from 'react';
import './style.css';

export default function Shimmer(props) {

    const { rounded, width, height, theme, source } = props;

    let contentClass = "loading-shimmer loading-shimmer-animation " + (theme ? theme: "");

    return (
        <>
        {
            source ?
            <img src={source} className={contentClass}
            style={{
                borderRadius: rounded ? rounded + "px" : "4px"
            }}/>:
            <span className={contentClass}
            style={{
                height: height ? height + "px" : "16px",
                width: width ? width + "px" : "100%",
                borderRadius: rounded ? rounded + "px" : "4px"
            }}>
            </span>
        }
        </>
    )
}