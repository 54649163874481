import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image, Modal, Button, Icon, Anchor, Notification, scrollTo } from "atomize";
import { FiPlay, FiShoppingCart } from 'react-icons/fi';

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';
import BaseHD from '../../assets/images/base-hd.png';
import BaseSquare from '../../assets/images/blank-600.png';
import image_default from '../../assets/images/default.png';

import logos_video from '../../assets/images/logos_video.png';
import logos_video_en from '../../assets/images/logos_video_en.png';

import LanguageContext from '../../contexts/language';
import AuthContext from '../../contexts/auth';

export default function PageEvent() {

    const { id } = useParams();

    const { signed, user, token } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(!signed){
            history.push(`/login?redirect=${location.pathname}`);
        }else{
            setLoading(true);
            loadPage();
        }
    }, [language, user]);

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };
    
    const [post, setPost] = useState([]);
    const [post_items, setPostItems] = useState([]);
    const [participantes, setParticipantes] = useState([]);
    const [mediadora, setMediadora] = useState([]);
    const [book, setBook] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [textAbout, setTextAbout] = useState('');
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');

    const [loadingCmm, setLoadingCmm] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');

    const [time, setTime] = useState(Date.now());
    
    async function loadPage(){
        const response = await api.post(`/event?id=${id}&lang=${lang}&v=2`);

        setPost(response.data.content.post[0]);
        setPostItems(response.data.content.post[0].post_items ? response.data.content.post[0].post_items : []);
        setParticipantes(response.data.content.post[0].participantes ? response.data.content.post[0].participantes : []);
        setMediadora(response.data.content.post[0].mediadora ? response.data.content.post[0].mediadora : []);
        setBook(response.data.content.post[0].book ? response.data.content.post[0].book : []);
        loadComment(response.data.content.post[0].id);

        setLoading(false);
    }


    async function loadComment(id_post){
        const response = await api.post(`/comments?id=${id_post}`);
        setComments(response.data.content.comments ? response.data.content.comments : []);
        setLoadingCmm(false);
    }

    async function addComments(e){
        e.preventDefault();
        setLoadingCmm(true);
        try{
            if(comment==""){
                setLoadingCmm(false);
                setError(true);
                setErrorMessage(language.comment_error);
                return;
            }
            await api.post(`/add-comment?id=${post.id}&comment=${comment}`, {}, config);
            setComment('');
            loadComment(post.id);

            scrollTo("#topComment")

        }catch(err){
            setLoadingCmm(false);
            setError(true);
            setErrorMessage(language.error);
        }
    }

    function writeComment(e){

        if(e.key === 'Enter'){
        	addComments(e);
        }else{
        	return true;
        }
    }


    useEffect(() => {
        const interval = setInterval(() => {
            setTime(Date.now());
            if(post.home=='yes'){
                loadComment(post.id);
            }
        }, 10000);
        return () => {
            clearInterval(interval);
        };

      }, [post]);
    
    return (
        <>
            <Header />
            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)} align={{xs: 'start',  md: 'center' }} rounded="md">
                <Icon
                    name="Cross"
                    pos="absolute"
                    top="10px"
                    right="10px"
                    size="24px"
                    onClose={() => setShowModal(false)}
                    onClick={() => setShowModal(false)}
                    cursor="pointer"
                />
                <Div d="flex">
                    <Text textAlign="justify" textSize="subheader">
                        <div className="text-html" dangerouslySetInnerHTML={{__html: textAbout}} />
                    </Text>
                </Div>
            </Modal>

            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{xs: 12,  md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '20px' }} textSize="display2" textColor="black800">
                                {
                                loading ?
                                <Shimmer width={300} height={30} />:
                                <>{post.title}</>
                                }
                            </Text>
                            <Text p={{ b: '10px' }} textSize="title" textColor="gray800">
                                {
                                loading ?
                                <Shimmer width={250} height={26} />:
                                <>{post.date}</>
                                }
                            </Text>
                            <Text p={{ b: '20px' }} textSize="subheader" textColor="gray600">
                                {
                                loading ?
                                <Shimmer width={200} height={22} />:
                                <>
                                    {post.time} Miami<br />
                                    {post.time_br} Brasília
                                </>
                                }
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col p="20px" size={{ xs: 12, md: 12 }} className="m-auto">
                            {
                                loading ?
                                <>
                                    <p className="mb-4">
                                        <Shimmer source={BaseHD} rounded={6} />
                                    </p>
                                </>:
                                <>
                                        <div className="content-video">
                                            <div className="embed-container">
                                                {(post.video==null || post.video=='') ?
                                                <>
                                                    <Div>
                                                        <Image shadow="3" rounded="6px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${post.banner})`}} />
                                                    </Div>
                                                </>:
                                                <>
                                                    {
                                                    post.video_type==='vimeo' &&
                                                    <iframe src={`https://player.vimeo.com/video/${post.video}?title=0&byline=0&portrait=0&autoplay=1`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                    {
                                                    post.video_type==='youtube' &&
                                                    <iframe src={`https://www.youtube.com/embed/${post.video}?rel=0&showinfo=0&autoplay=1`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                    {
                                                    post.video_type==='facebook' &&
                                                    <iframe src={`https://www.facebook.com/plugins/video.php?show_text=false&href=${post.video}`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                </>
                                                }
                                                
                                            </div>
                                            <Image src={lang=='pt' ? logos_video : logos_video_en } />
                                        </div>
                                    {
                                    post.video_2!='' &&
                                        <div className="content-video">
                                            <div className="embed-container">
                                                {
                                                post.video_type_2==='vimeo' &&
                                                <iframe src={`https://player.vimeo.com/video/${post.video_2}?title=0&byline=0&portrait=0`} frameborder="0" allowfullscreen></iframe>
                                                }
                                                {
                                                post.video_type_2==='youtube' &&
                                                <iframe src={`https://www.youtube.com/embed/${post.video_2}?rel=0&showinfo=0`} frameborder="0" allowfullscreen></iframe>
                                                }
                                                {
                                                post.video_type_2==='facebook' &&
                                                <iframe src={`https://www.facebook.com/plugins/video.php?show_text=false&href=${post.video_2}`} frameborder="0" allowfullscreen></iframe>
                                                }
                                            </div>
                                            <Image src={lang=='pt' ? logos_video : logos_video_en } />
                                        </div>
                                    }
                                </>
                            }
                        </Col>
                        <Col p="20px" size={{ xs: 12, md: 8 }} className="m-auto">
                            {
                                loading ?
                                <>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={350} />
                                        <Shimmer />
                                        <Shimmer width={100} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                        <Shimmer />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer />
                                        <Shimmer width={300} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={400} />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                    </p>
                                    <p className="mb-4">
                                        <Shimmer />
                                        <Shimmer width={400} />
                                        <Shimmer />
                                        <Shimmer width={200} />
                                    </p>
                                </>:
                                <>
                                    {post_items.map((item, index) => (
                                    <Div className="content-post" textSize="paragraph" textColor="gray900">

                                        <Div m={{b: '10px'}} id="topComment">
                                            <Text textSize="title" textColor="black700" textAlign="center">{language.comments}</Text>
                                        </Div>
                                        <Div bg="gray100" rounded="12px" p={{l:'20px', r:'20px', t:'10px', b:'10px'}} shadow="3" maxH="800px" overflow="scroll">
                                            <Row className="row-comment">
                                                {comments.length>0 ?
                                                <>
                                                {comments.map(item => (
                                                <>
                                                    <Col key={item.id} size="12" d="flex" align="flex-start" justify="flex-start">
                                                        <Div>
                                                            <Image shadow="1" w="48px" m={{r: '10px'}} rounded="circle" src={BaseSquare} className="cover-bg" style={{backgroundImage: `url(${item.image==null ? image_default : item.image})`}} />
                                                        </Div>
                                                        <Div>
                                                            <Div><Text tag="span" textColor="black700">{item.name}</Text></Div>
                                                            <Div><Text tag="span" textColor="gray900" dangerouslySetInnerHTML={{__html: item.comment}} /></Div>
                                                        </Div>
                                                    </Col>
                                                    <div className="divider-comment"></div>
                                                </>
                                                ))}
                                                </>:
                                                <Text textAlign="center" textColor="gray900" p={{t: '20px'}} m="auto">{language.no_comments}</Text>
                                                }

                                            </Row>
                                        </Div>
                                        
                                        <Div h="40px"></Div>
                                    
                                        {post.home=='yes' &&
                                        <>
                                        <form onSubmit={addComments}>
                                            <Div className={`${ loadingCmm ? 'loading-sending' : '' }`}>
                                                <Row>
                                                    <Col size="12" w="100%" d="flex" align="center" justify="flex-start" flexDir="column">
                                                        <textarea value={comment} onKeyPress={writeComment} onChange={e => setComment(e.target.value)} className="custom-input textarea" />
                                                        <Button
                                                            type="submit"
                                                            h="44px"
                                                            textWeight="600"
                                                            textSize="body"
                                                            textColor="info700"
                                                            hoverTextColor="info900"
                                                            bg="info200"
                                                            hoverBg="info300"
                                                            rounded="lg"
                                                            m={{t: '20px'}}
                                                            p={{x: '40px'}}
                                                        >
                                                            {language.form_send}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Div>
                                        </form>
                                        <Div h="40px"></Div>
                                        </>
                                        }

                                        {
                                        item.type==='image' &&
                                            <div className="content-image" key={`image-${index}`}>
                                                <Row>
                                                    <Col size={{ md: 12 }} className="m-auto" textAlign="justify">
                                                        <img src={item.image} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                        {
                                        item.type==='text' &&
                                            <div className="content-text" key={`text-${index}`} dangerouslySetInnerHTML={{__html: item.text}} />
                                        }
                                        {
                                        item.type==='video' &&
                                            <div className="content-video" key={`video-${index}`}>
                                                <div className="embed-container">
                                                    {
                                                    item.video_type==='vimeo' &&
                                                    <iframe src={`https://player.vimeo.com/video/${item.video}?title=0&byline=0&portrait=0`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                    {
                                                    item.video_type==='youtube' &&
                                                    <iframe src={`https://www.youtube.com/embed/${item.video}?rel=0&showinfo=0`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                    {
                                                    item.video_type==='facebook' &&
                                                    <iframe src={`https://www.facebook.com/plugins/video.php?show_text=false&href=${item.video}`} frameborder="0" allowfullscreen></iframe>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </Div>
                                    ))}

                                    {participantes.length>0 &&
                                    <>
                                        <Row>
                                            <Col size={{ xs: 12, md: 12 }}>
                                                <Text p={{ t: '30px', b: '15px' }} textSize="display1" textColor="black800" textAlign="center">
                                                    {participantes.length==1 ? language.title_participante : language.title_participantes}
                                                </Text>
                                            </Col>
                                        </Row>
                                        <Row align="flex-start" justify="center">
                                            {participantes.map(item => (
                                            <Col key={item.id} p="10px" size={{ xs: 6, md: 4 }}>
                                                {item.image!="" && <Image shadow="3" rounded="circle" src={BaseSquare} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />}
                                                <Text p={{ t: '4px' }} textSize="subheader" textColor="black600" textAlign="center">{item.name}</Text>
                                                <Button
                                                    h="2rem"
                                                    p={{ x: "0.75rem" }}
                                                    m="auto"
                                                    textWeight="600"
                                                    textSize="body"
                                                    textColor="gray900"
                                                    hoverTextColor="gray900"
                                                    bg="gray300"
                                                    hoverBg="gray400"
                                                    rounded="lg"
                                                    onClick={() => {
                                                        setShowModal(true);
                                                        setTextAbout(item.about)
                                                    }}
                                                    disabled={item.about=='' ? true : false}
                                                >
                                                   Bio
                                                </Button>
                                            </Col>
                                            ))}
                                        </Row>
                                    </>
                                    }
                                    {mediadora.length>0 &&
                                    <>
                                        <Row>
                                            <Col size={{ xs: 12, md: 12 }}>
                                                <Text p={{ t: '30px', b: '15px' }} textSize="display1" textColor="black800" textAlign="center">{language.title_mediadores}</Text>
                                            </Col>
                                        </Row>
                                        <Row align="flex-start" justify="center">
                                            {mediadora.map(item => (
                                            <Col key={item.id} p="10px" size={{ xs: 6, md: 4 }}>
                                                {item.image!="" && <Image shadow="3" rounded="circle" src={BaseSquare} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />}
                                                <Text p={{ t: '4px' }} textSize="subheader" textColor="black600" textAlign="center">{item.name}</Text>
                                                <Button
                                                    h="2rem"
                                                    p={{ x: "0.75rem" }}
                                                    m="auto"
                                                    textWeight="600"
                                                    textSize="body"
                                                    textColor="gray900"
                                                    hoverTextColor="gray900"
                                                    bg="gray300"
                                                    hoverBg="gray400"
                                                    rounded="lg"
                                                    onClick={() => setShowModal(true)}onClick={() => {
                                                        setShowModal(true);
                                                        setTextAbout(item.about)
                                                    }}
                                                    disabled={item.about=='' ? true : false}
                                                >
                                                   Bio
                                                </Button>
                                            </Col>
                                            ))}
                                        </Row>
                                    </>
                                    }

                                    {book.length>0 &&
                                    <Row>
                                        <Col size={{ xs: 12, md: 12 }}>
                                            <Text p={{ t: '30px', b: '15px' }} textSize="display1" textColor="black800" textAlign="center">{language.buy_book}</Text>
                                        </Col>
                                        {book.map(item => (
                                        <Col key={item.id} size={{ xs: 12, md: 8 }} justify="center" className="m-auto">
                                            <Div p={{ t: '30px' }} d="flex" align="flex-start" justify="center">
                                                <Div p={{ r: '10px' }}>
                                                    <Image shadow="2" rounded="8px" style={{width: '160px'}} src={item.image} />
                                                </Div>
                                                <Div p={{ l: '10px' }} textAlign="left">
                                                    <Text textColor="black800" textSize="title">{item.title}</Text>
                                                    <Div p="5px"></Div>
                                                    {item.link!=null ?
                                                    <Anchor href={item.link} target="_blank">
                                                        <Button
                                                            suffix={
                                                                <FiShoppingCart size={18} style={{marginLeft: '6px'}} />
                                                            }
                                                            bg="black600"
                                                            hoverBg="black400"
                                                            shadow="2"
                                                            hoverShadow="3"
                                                            cursor="pointer"
                                                        >
                                                            {language.buy}
                                                        </Button>
                                                    </Anchor>:
                                                    <Button
                                                        suffix={
                                                            <FiShoppingCart size={18} style={{marginLeft: '6px'}} />
                                                        }
                                                        bg="black600"
                                                        shadow="2"
                                                        cursor="pointer"
                                                        disabled={true}
                                                    >
                                                        {language.buy}
                                                    </Button>
                                                    }
                                                    <Div p="5px"></Div>
                                                    <Link to={`/books`} className="b-link">
                                                        <Text tag="span" textSize="subheader">{language.all_books}</Text>
                                                    </Link>
                                                </Div>
                                            </Div>
                                        </Col>
                                        ))}
                                    </Row>
                                    }
                                </>
                            }
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}