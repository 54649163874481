import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Input, Button, Icon, Notification } from "atomize";
import { ImCreditCard, ImPaypal } from "react-icons/im";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import AuthContext from '../../contexts/auth';
import LanguageContext from '../../contexts/language';

export default function PageDonation() {

    const { signed, user, token } = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    
    const { language } = useContext(LanguageContext);
    const [price, setPrice] = useState(5);

    function setPriceWrite(){
        if(price < 5){
            setPrice(5);
        }
    }
    useEffect(() => {
        if(!signed){
            history.push(`/login?redirect=${location.pathname}`);
        }
    }, [user]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    async function buyPaypal(){
        
        setError(false);
        setLoading(true);
        try{
            const response = await api.post(
                `/paypal?content=donation&donation_price=${price}`
            , {}, config);

            setError(false);
            window.location.href = response.data.url;

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
        }
    }
    async function buyCreditCard(){
        setError(false);
        setLoading(true);
        try{
            const response = await api.post(
                `/square?content=donation&donation_price=${price}`
            , {}, config);

            setError(false);
            window.location.href = `/payment-square/${response.data.payment_id}`;

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
        }
    }

    return (
        <>
            <Header />
            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Div bg="gray300" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col p="30px" size={{ md: 4 }} bg="white" shadow="3" rounded="lg" className={`${ loading ? 'loading-sending m-auto' : 'm-auto' }`} textAlign="center">
                                <Div p={{ t: '10px', b: '30px' }}>
                                    <Text p={{ b: '10px' }} textSize="title" textColor="black800">
                                        {language.donation}
                                    </Text>
                                </Div>
                                <Div p={{ b: '30px' }} d="flex" flexDir="column" align="center" justify="center">
                                    <Text m={{t: '0px', b: '4px'}} textTransform="uppercase" textAlign="center" textColor="black200" textSize="paragraph">
                                        {language.donation_amout}:
                                    </Text>
                                    <input className="custom-input donate" value={price} onBlur={e => setPriceWrite(e.target.value)} onChange={e => setPrice(e.target.value)} type="tel" />
                                </Div>
                                <Text m={{t: '0px', b: '4px'}} textTransform="uppercase" textAlign="center" textColor="black200" textSize="paragraph">
                                    {language.pay_with}:
                                </Text>
                                <Div d="flex" flexDir="row" justify="center">
                                    <Button
                                        type="button"
                                        onClick={buyPaypal}
                                        prefix={
                                            <ImPaypal size={18} style={{marginRight: '6px'}} />
                                        }
                                        bg="info800"
                                        hoverBg="info900"
                                        shadow="2"
                                        hoverShadow="3"
                                        cursor="pointer"
                                    >
                                        {language.paypal}
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={buyCreditCard}
                                        prefix={
                                            <ImCreditCard size={18} style={{marginRight: '6px'}} />
                                        }
                                        bg="black800"
                                        hoverBg="dark"
                                        shadow="2"
                                        hoverShadow="3"
                                        cursor="pointer"
                                        m={{l: '10px'}}
                                    >
                                        {language.credit_card}
                                    </Button>
                                </Div>
                              
                        </Col>
                    </Row>
                </Container>
            </Div>

            <Footer />
        </>
        
    );
}