import React from 'react';
import { Div, Container, Row, Col, Image } from "atomize";

import Base from '../assets/images/blank-br.png';
import Shimmer from './Shimmer';

export default function ShimmerBookFeatured(props) {

    const { isHome } = props;

    return (
        <Div bg="white" p={{ t: isHome ? '60px' : { xs: '120px', md: '200px' }, b: '60px' }}>
            <Container>
                <Row d="flex" justify="center">
                    <Col size={{ md: 8 }}>
                        <Shimmer source={Base} rounded={12} />
                    </Col>
                </Row>
            </Container>
        </Div>
    );
}