import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image } from "atomize";
import { FaArrowLeft } from "react-icons/fa";

import api from '../services/api';
import Header from '../components/Header';
import Footer from '../components/Footer';

import AuthContext from '../contexts/auth';
import LanguageContext from '../contexts/language';

export default function PurchasesList(props) {
    
    const { signed, token, user } = useContext(AuthContext);
    const { id } = useParams();

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(!signed){
            history.push(`/login?redirect=${location.pathname}`);
        }else{
            setLoading(true);
            loadPage();
        }
    }, [user]);

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [purchases, setPurchases] = useState([]);
    const [purchase, setPurchase] = useState([]);

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    async function loadPage(){

        const response = await api.post(`/purchase/${id}?lang=${lang}`, {}, config);
        setPurchase(response.data.shopping);
        setPurchases(response.data.shopping.shopping_items);
        setLoading(false);
    }

    return (
        <>
            <Header />
            <Div bg="gray300" p={{ t: { xs: '120px', md: '200px' }, b: '120px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '40px' }} textSize="display2" textColor="black800">{language.menu_purchases}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col p="30px" size={{ md: 8 }} bg="white" shadow="3" rounded="lg" className="m-auto" textAlign="center">
                                <Div p={{ b: '30px' }} d="flex" flexDir="column" align="center" justify="center">
                                    {purchases.length==0 ?
                                        <div className="m-auto">
                                            <div className="content-error mb10">
                                                {lang==='pt' ? "Nenhuma Compra Encontrada!": "No Purchase Found!" }
                                            </div>
                                        </div>:
                                        <Container>
                                            <Row>
                                                <Col size="12" textAlign="left" p={{b: '20px'}}>
                                                    <Link to="/account"><FaArrowLeft size={22} /> ID {purchase.payment_id}</Link>
                                                </Col>
                                                <Col size="12" textAlign="left" p={{b: '20px'}}>
                                                    <div>{lang==='pt' ? "FORMA DE PAGAMENTO:": "PAYMENT METHOD:" } <strong>{purchase.payment_type==='paypal' ? "PAYPAL" : purchase.payment_type==='square' ? "SQUARE" : purchase.payment_type==='cortesia' ? "COURTESY" : "PAGSEGURO" }</strong></div>
                                                    <div>TOTAL: 
                                                    <strong className="cl16">
                                                    {Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(purchase.payment_price)}
                                                    </strong></div>
                                                    {
                                                    purchase.payment_status=="paid" || purchase.payment_status=="3" ?
                                                    <Text textColor="success700">
                                                        {lang==='pt' ? "PAGO COM SUCESSO": "SUCCESSFUL PAY" }
                                                    </Text>:
                                                    <Text textColor="warning700">
                                                        {lang==='pt' ? "PROCESSANDO PAGAMENTO": "PROCESSING PAYMENT " }
                                                    </Text>
                                                    }
                                                </Col>
                                                <Col size="12" textAlign="left">
                                                    <Text textColor="black400">
                                                        ITEM:
                                                    </Text>
                                                    {purchases.map(item => (
                                                        <Text textColor="gray900">
                                                            {
                                                                item.title=="brazil-in-miami" ? 
                                                                <>{language.title_bookfeatured}</>:
                                                                <>{language.donation}</>
                                                            }
                                                        </Text>
                                                   ))}
                                                </Col>
                                            </Row>
                                        </Container>
                                        }
                                </Div>
                              
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
    );
}