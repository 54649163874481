import React, { useContext, useState, useEffect } from 'react';
import {
    SquarePaymentForm,
    CreditCardNumberInput,
    CreditCardExpirationDateInput,
    CreditCardPostalCodeInput,
    CreditCardCVVInput,
    CreditCardSubmitButton
} from 'react-square-payment-form';
import 'react-square-payment-form/lib/default.css';
import { useParams, useHistory } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Notification } from "atomize";
import { BsExclamationCircle } from "react-icons/bs";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';

import AuthContext from '../../contexts/auth';
import LanguageContext from '../../contexts/language';

export default function PaymentSquare() {

    //const [APPLICATION_ID, setAppID] = useState('sandbox-sq0idb-slE6irQOwsqdRSlMfTeV3w');
    //const [LOCATION_ID, setLocationID] = useState('LM9XC27SSC5SD');

    const [APPLICATION_ID, setAppID] = useState('sq0idp-VF1_5hrfaX8FHaUMKaDbwA');
    const [LOCATION_ID, setLocationID] = useState('FJ117FD5WNVZT');

    const { signed, signIn, token, signOut } = useContext(AuthContext);
    const { id } = useParams();
    const history = useHistory();

    const { language } = useContext(LanguageContext);
    const [content, setContent] = useState([]);

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };
    const [loading, setLoading] = useState(false);
    const [loading_page, setLoadingPage] = useState(false);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState([]);

    async function loadPage(){
        setLoadingPage(true);
        try{
            const response = await api.post(
                `/payment-square?payment_id=${id}`
            , {}, config);

            setContent(response.data.shopping);
            setLoadingPage(false);
            setError(false);
        }catch(err){
            setLoadingPage(false);
            setError(true);
            setErrorMessage([err.message]);
        }
    }
    useEffect(() => {
        loadPage();
    }, []);
    const cardNonceResponseReceived = (errors, nonce, cardData, buyerVerificationToken) => {
        if (errors) {
            setErrorMessage(errors);
            setLoading(false);
            setError(true);
            return
        }
        setErrorMessage([]);
        setError(false);
        //enviar para o backend
        updatePayment(nonce, buyerVerificationToken);
        
    }
    async function updatePayment(nonce, buyerVerificationToken){
        try{
            const response = await api.post(
                `/update-payment-square?payment_id=${id}&nonce=${nonce}&buyer=${buyerVerificationToken}`
            , {}, config);

            window.location.href = `/payment-process/${id}`;
            setError(false);
        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage([language.error_payment]);
        }
    }
    function submitPayment(e){
        e.preventDefault();
        setLoading(true);
    }
    function createVerificationDetails() {
        return {
            amount: content.price_cents,
            currencyCode: "USD",
            intent: "CHARGE",
            billingContact: {
                familyName: content.last_name,
                givenName: content.name,
                email: content.email,
                country: content.country,
                city: content.city,
                phone: content.phone
            }
        }
    }
    return (
        <>
            <Header />
            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Div bg="gray300" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col p="30px" size={{ md: 5 }} bg="white" shadow="3" rounded="lg" className="m-auto" textAlign="center">
                           
                                <Div p={{ t: '10px', b: '30px' }}>
                                    <Text p={{ b: '10px' }} textSize="title" textColor="black800">
                                        {language.credit_card_payment}
                                    </Text>
                                </Div>
                                <Div p={{ b: '30px' }}>
                                    <form className={`${ loading ? 'loading-sending' : '' }`} onSubmit={submitPayment} id="form-payment-card" style={{maxWidth: 380, margin:'auto'}}>
                                        {
                                        loading_page &&
                                        <>
                                            <fieldset className="sq-fieldset">
                                                <Shimmer width={150} />
                                                <Shimmer height={56} />
                                                <div className="sq-form-third">
                                                    <Shimmer width={80} />
                                                    <Shimmer height={56} />
                                                </div>
                                                <div className="sq-form-third">
                                                    <Shimmer width={80} />
                                                    <Shimmer height={56} />
                                                </div>
                                                <div className="sq-form-third">
                                                    <Shimmer width={80} />
                                                    <Shimmer height={56} />
                                                </div>
                                            </fieldset>
                                            <Shimmer height={56} />
                                        </>
                                        }
                                        <div className={`${loading_page && 'content-hidden'}`}>
                                            <SquarePaymentForm
                                                sandbox={false}
                                                applicationId={APPLICATION_ID}
                                                locationId={LOCATION_ID}
                                                cardNonceResponseReceived={cardNonceResponseReceived}
                                                createVerificationDetails={createVerificationDetails}
                                                placeholderExpiration={language.card_date_p}
                                                >
                                                <fieldset className="sq-fieldset">
                                                    <CreditCardNumberInput label={language.card_number} />
                                                    <div className="sq-form-third">
                                                        <CreditCardExpirationDateInput label={language.card_date} />
                                                    </div>
                                                    <div className="sq-form-third">
                                                        <CreditCardCVVInput label={language.card_cvv} />
                                                    </div>
                                                    <div className="sq-form-third">
                                                        <CreditCardPostalCodeInput label={language.card_zipcode} />
                                                    </div>
                                                </fieldset>
                                                <CreditCardSubmitButton>
                                                    {language.pay} {Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(content.price)}
                                                </CreditCardSubmitButton>
                                            </SquarePaymentForm>
                                        </div>
                                    </form>
                                </Div>
                              
                        </Col>
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}