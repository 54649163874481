import React, { createContext, useState, useEffect } from 'react';

import api from '../services/api';

const AuthContext = createContext({ signed: false, user: {}, token: '' });

export const AuthProvider = ({ children }) => {
    
    const [user, setUser] = useState(localStorage.getItem('user'));
    const [token, setToken] = useState(localStorage.getItem('token'));
    
    useEffect(() => {
        async function loadStorageData() {
            const storageUser = localStorage.getItem('user');
            const storageToken = localStorage.getItem('token');

            if (storageUser && storageToken) {
                setUser(JSON.parse(storageUser));
                setToken(storageToken);
            }
        }

        loadStorageData();
    }, [])

    async function signIn(email, password) {

        const response = await api.post('/login', {
            email,
            password
        });
        const { data } = response.data;

        if(data.user) {
            setUser(data.user);
            setToken(data.token);
      
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('token', data.token);
        }
        return data;

    }

    function signOut() {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        setUser(null);
        setToken(null);
    }

    return (
        <AuthContext.Provider value={{ signed: !! user, user, token, signIn, signOut }} >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
