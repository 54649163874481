import React, { useContext, useState, useEffect } from 'react';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Books from '../../components/Books';
import BookFeatured from '../../components/BookFeatured';

import ShimmerBooks from '../../components/ShimmerBooks';
import ShimmerBookFeatured from '../../components/ShimmerBookFeatured';

import LanguageContext from '../../contexts/language';

export default function PageBooks() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);

    async function loadPage(){
 
        const response = await api.post(`/books?lang=${lang}`);
        setBooks(response.data.content.books ? response.data.content.books: []);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language]);

    return (
        <div className="content-app">
            <Header />
            {loading ?
            <>
                <ShimmerBooks />
                <ShimmerBookFeatured isHome={true} />
            </>:
            <>
                <Books content={books} />
                <BookFeatured isHome={true} />
            </>
            }
            <Footer />
        </div>
    );
}