import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image } from "atomize";

import LanguageContext from '../contexts/language';

export default function Results(props) {

    const { language } = useContext(LanguageContext);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    return (
        <Div bg="gray200" p={{ t: { xs: '120px', md: '160px' }, b: '20px' }}>
            <Container>
                <Row>
                    <Col size={{ md: 12 }}>
                        <Text textSize="display1" textColor="black800" textAlign="center">{language.title_results}: {query.get('q')}</Text>
                    </Col>
                </Row>
            </Container>
        </Div>
    );
}