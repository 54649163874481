import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PurchasesList from '../../components/PurchasesList';

export default function Purchases() {

    return (
        <div className="custom-page">
            <Header />
            <PurchasesList onPage={true} />
            <Footer />
        </div>
    );
}