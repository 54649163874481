import React, { useContext, useState, useEffect } from 'react';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Events from '../../components/Events';
import Authors from '../../components/Authors';
import BookFeatured from '../../components/BookFeatured';
import Books from '../../components/Books';
import Now from '../../components/Now';

import ShimmerEvents from '../../components/ShimmerEvents';
import ShimmerAuthors from '../../components/ShimmerAuthors';
import ShimmerBookFeatured from '../../components/ShimmerBookFeatured';
import ShimmerBooks from '../../components/ShimmerBooks';
import ShimmerNow from '../../components/ShimmerNow';
import ShimmerHeaderCarousel from '../../components/ShimmerHeaderCarousel';

import LanguageContext from '../../contexts/language';

export default function Home() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [events, setEvents] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [authors2, setAuthors2] = useState([]);
    const [news, setNews] = useState([]);
    const [books, setBooks] = useState([]);
    const [now, setNow] = useState([]);
    const [banners, setBanners] = useState([]);
    
    const [loading, setLoading] = useState(true);

    async function loadPage(){
 
        const response = await api.post(`/home?lang=${lang}`);
        setBanners(response.data.content.banners ? response.data.content.banners: []);
        setEvents(response.data.content.events ? response.data.content.events: []);
        setAuthors(response.data.content.authors ? response.data.content.authors: []);
        setAuthors2(response.data.content.authors_2 ? response.data.content.authors_2: []);
        setNews(response.data.content.news ? response.data.content.news: []);
        setBooks(response.data.content.books ? response.data.content.books: []);
        setNow(response.data.content.now ? response.data.content.now: []);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language]);

    return (
        <div className="content-app">
            {loading ?
            <>
                <ShimmerHeaderCarousel isHome={true} />
                <ShimmerNow isHome={true} />
                <ShimmerEvents isHome={true} />
                <ShimmerAuthors isHome={true} />
                <ShimmerBookFeatured isHome={true} />
                <ShimmerBooks isHome={true} />
            </>:
            <>
                <Header isHome={true} content={banners} />
                <Now isHome={true} content={now} />
                <Events isHome={true} content={events} />
                <Authors isHome={true} content={authors} />
                <Authors isHome={true} special={true} content={authors2} />
                <BookFeatured isHome={true} />
                <Books isHome={true} content={books} />
            </>
            }
            <Footer />
        </div>
    );
}
