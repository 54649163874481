import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image, Button } from "atomize";
import { BiChevronRight } from "react-icons/bi";

import LanguageContext from '../contexts/language';
import BaseHD from '../assets/images/base-hd.png';
import Tutoral from '../assets/images/tutorial.jpg';

export default function Now(props) {

    const { language } = useContext(LanguageContext);

    const { content, isHome } = props;
    
    return (
        <>
        
        <Div bg="white" p={{ t: isHome ? '60px' : { xs: '120px', md: '200px' }, b: '60px' }}>
            <Container>
                {/*<Row>
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '20px', l: '5%' }} textSize="display1" textColor="black800" textAlign="left">{language.now}</Text>
                    </Col>
                </Row>*/}
                <Row align="center" justify="space-around">
                    {/*{content.map(item => (
                    <Col key={item.id} p="20px" size={{ xs: 12, md: 6 }}>
                        <Link to={`/event/${item.url}`} className="no-underline menu-opacity">
                            <Image shadow="3" rounded="20px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center">{item.title}</Text>
                            <Text textSize="paragraph" textColor="gray800" textAlign="center" dangerouslySetInnerHTML={{__html: item.text}} />
                        </Link>
                    </Col>
                    ))}*/}
                    <Col p="20px" size={{ xs: 12, md: 6 }}>
                        <Link to="/tutorial" className="no-underline menu-opacity">
                            <Image shadow="3" rounded="20px" src={BaseHD} className="cover-bg" style={{backgroundImage: `url(${Tutoral})`}} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center">{language.tutorial}</Text>
                        </Link>
                    </Col>
                    <Col size={{ xs: 12, md: 4 }}>
                        <Row align="center">
                            <Col p="20px" size={{ xs: 12, md: 12 }} d="flex" flexDir="column" align="center" justify="center">
                                <Link to="/donation" className="no-underline menu-opacity">
                                    <Button
                                        h="3.5rem"
                                        p={{ x: "2.5rem" }}
                                        textSize="body"
                                        textWeight="700"
                                        bg="black600"
                                        hoverBg="black400"
                                        shadow="2"
                                        hoverShadow="3"
                                        cursor="pointer"
                                    >
                                        {language.donation}
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Div>
        </>
    );
}