import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Button, Image, Anchor } from "atomize";
import { FiShoppingCart } from "react-icons/fi";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';
import Base from '../../assets/images/blank-900.png';

import LanguageContext from '../../contexts/language';

export default function PageText() {

    const { id } = useParams();
    
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');
    
    const [book, setBook] = useState([]);
    const [author, setAuthor] = useState([]);
    const [loading, setLoading] = useState(true);
    
    async function loadPage(){
        const response = await api.post(`/book?id=${id}&lang=${lang}`);
        setBook(response.data.content.book[0] ? response.data.content.book[0] : []);
        setAuthor(response.data.content.book[0].author ? response.data.content.book[0].author : []);
        setLoading(false);
    }
    
    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language, id]);

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size="4">
                            <Div>
                                {loading ?
                                <Shimmer source={Base} rounded={8} />:
                                <Image shadow="3" rounded="8px" src={Base} className="cover-bg" style={{backgroundImage: `url(${book.image})`}} />
                                }
                            </Div>
                        </Col>
                        {loading ?
                        <Col size="8">
                            <Text textColor="black800" textSize="display1"><Shimmer width={200} height={34} /></Text>
                            <Text m={{t: '15px'}} textColor="gray600" textSize="body">
                                <Shimmer width={150} />
                            </Text>
                            <Text m={{t: '15px'}} textColor="gray600" textSize="body">
                                <Shimmer width={180} />
                            </Text>
                            <Text m={{t: '15px', b: '15px'}} textColor="gray600" textSize="body">
                                <Shimmer width={350} />
                                <Shimmer width={250} />
                                <Shimmer width={350} />
                                <Shimmer width={100} />
                            </Text>
                        </Col>:
                        <Col size="8">
                            <Text textColor="black800" textSize="display1">{book.title}</Text>
                            <Text m={{t: '15px'}} textColor="gray600" textSize="body">
                                <strong>{language.author}: </strong>
                                {author.map(item => (
                                <Link to={`/author/${item.url}`}>
                                    <Text tag="span" m={{ r: '10px' }} textSize="body" textColor="gray600">{item.name}</Text>
                                </Link>
                                ))}
                            </Text>
                            {book.genre!='' &&
                            <Text m={{t: '15px'}} textColor="gray600" textSize="body">
                                <strong>{language.genre}: </strong>
                                <Text tag="span" m={{ r: '10px' }} textSize="body" textColor="gray600">{book.genre}</Text>
                            </Text>
                            }
                            <Text m={{t: '15px', b: '15px'}} textColor="gray900" textSize="body">{book.text}</Text>
                            {book.link!=null ?
                            <Anchor href={book.link} target="_blank">
                                <Button
                                    suffix={
                                        <FiShoppingCart size={18} style={{marginLeft: '6px'}} />
                                    }
                                    bg="black600"
                                    hoverBg="black400"
                                    shadow="2"
                                    hoverShadow="3"
                                    cursor="pointer"
                                >
                                    {language.buy}
                                </Button>
                            </Anchor>:
                            <Button
                                suffix={
                                    <FiShoppingCart size={18} style={{marginLeft: '6px'}} />
                                }
                                bg="black600"
                                shadow="2"
                                cursor="pointer"
                                disabled={true}
                            >
                                {language.buy}
                            </Button>
                            }
                        </Col>
                        }
                    </Row>
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}