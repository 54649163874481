import React from 'react';
import { StyleReset } from 'atomize';
import './custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { LanguageProvider } from './contexts/language';
import { AuthProvider } from './contexts/auth';

import Routes from './routes';

function App() {
    return (
        <LanguageProvider>
            <AuthProvider>
                <StyleReset />
                <Routes />
            </AuthProvider>
        </LanguageProvider>
    );
}

export default App;