import React from 'react';
import { Div, Text, Container, Row, Col } from "atomize";

import Base from '../assets/images/base-hd.png';
import Shimmer from './Shimmer';

export default function ShimmerEvents(props) {

    const { isHome } = props;
    
    return (
        <Div bg="gray200" p={{ t: isHome ? '40px' : { xs: '120px', md: '200px' }, b: '40px' }}>
            <Container>
                <Row>
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '40px' }} textSize="display1" textColor="black800" textAlign="center"><Shimmer width={250} height={30} /></Text>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col p="20px" size={{ xs: 12, md: 4 }}>
                        <div className="no-underline">
                            <Shimmer source={Base} rounded={12}  />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center"><Shimmer width={120} /></Text>
                            <Text textSize="paragraph" textColor="gray800" textAlign="center"><Shimmer width={200} /></Text>
                        </div>
                    </Col>
                    <Col p="20px" size={{ xs: 12, md: 4 }}>
                        <div className="no-underline">
                            <Shimmer source={Base} rounded={12} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center"><Shimmer width={120} /></Text>
                            <Text textSize="paragraph" textColor="gray800" textAlign="center"><Shimmer width={200} /></Text>
                        </div>
                    </Col>
                    <Col p="20px" size={{ xs: 12, md: 4 }}>
                        <div className="no-underline">
                            <Shimmer source={Base} rounded={12} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center"><Shimmer width={120} /></Text>
                            <Text textSize="paragraph" textColor="gray800" textAlign="center"><Shimmer width={200} /></Text>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Div>
    );
}