import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import PageAuthors from './pages/PageAuthors';
import PageAuthors2 from './pages/PageAuthors2';
import PageBooks from './pages/PageBooks';
import PageEvents from './pages/PageEvents';
import PageNews from './pages/PageNews';
import Search from './pages/Search';

import Post from './pages/Post';
import PageText from './pages/PageText';
import PageEvent from './pages/PageEvent';
import PageBook from './pages/PageBook';
import PageAuthor from './pages/PageAuthor';
import PageWelcome from './pages/PageWelcome';
import PageTutorial from './pages/PageTutorial';
import PageBookFeatured from './pages/PageBookFeatured';
import PageDonation from './pages/PageDonation';

import Page404 from './pages/Page404';

import Login from './pages/Login';
import Forgot from './pages/Forgot';
import CreateAccount from './pages/CreateAccount';
import Account from './pages/Account';
import Contact from './pages/Contact';
import Purchases from './pages/Purchases';
import Purchase from './pages/Purchase';

import PaymentCapture from './pages/PaymentCapture';
import PaymentProcess from './pages/PaymentProcess';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentSquare from './pages/PaymentSquare';

import ScrollToTop from './components/ScrollToTop';

export default function Routes() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/welcome" component={PageWelcome} />
                <Route path="/authors" component={PageAuthors} />
                <Route path="/books" component={PageBooks} />
                <Route path="/events" component={PageEvents} />
                <Route path="/news" component={PageNews} />
                <Route path="/event/:id" component={PageEvent} />
                <Route path="/post/:id" component={Post} />
                <Route path="/page/:id" component={PageText} />
                <Route path="/book/:id" component={PageBook} />
                <Route path="/author/:id" component={PageAuthor} />
                <Route path="/special-participants" component={PageAuthors2} />
                <Route path="/search" component={Search} />

                <Route path="/login" component={Login} />
                <Route path="/forgot" component={Forgot} />
                <Route path="/create-account" component={CreateAccount} />
                <Route path="/account" component={Account} />
                <Route path="/contact" component={Contact} />
                <Route path="/tutorial" component={PageTutorial} />
                <Route path="/brazil-in-miami" component={PageBookFeatured} />

                <Route path="/payment-capture/:id" component={PaymentCapture} />
                <Route path="/payment-process/:id" component={PaymentProcess} />
                <Route path="/payment-success/:id" component={PaymentSuccess} />
                <Route path="/payment-square/:id" component={PaymentSquare} />
                <Route path="/donation" component={PageDonation} />

                <Route path="/purchases" component={Purchases} />
                <Route path="/purchase/:id" component={Purchase} />

                <Route component={Page404} />
            </Switch>
        </BrowserRouter>
    );
}