import React, { useContext, useState, useEffect } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { Image, Div, Row, Col, Input, Icon } from "atomize";
import { NavLink, Link, useHistory, useLocation } from 'react-router-dom';
import { VscClose } from "react-icons/vsc";
import { FaFacebook, FaInstagram } from "react-icons/fa";

import HeaderCarousel from '../components/HeaderCarousel';

import imgTop from '../assets/images/top.jpg';
import logo from '../assets/images/logo.png';
import s_logo_1 from '../assets/images/s_logo_1.png';
import Base from '../assets/images/blank-600.png';
import avatar from '../assets/images/default.png';

import LanguageContext from '../contexts/language';
import AuthContext from '../contexts/auth';

export default function Header(props) {

    const { content, isHome, isSearch } = props;
    
    function setLanguage(lang) {
        setLanguageStore(lang);
        closeMenus();
    }

    const { signed, user, signOut } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if(signed){
            loadUser();
        }
    }, [user]);

    function loadUser() {
        setImage(user.image==='' ? avatar : user.image);
    }
    const [image, setImage] = useState(signed ? user.image==='' ? avatar : user.image : avatar);
    const { language, setLanguageStore } = useContext(LanguageContext);

    const storageSearch = localStorage.getItem('search');
    const [search, setSearch] = useState((storageSearch=='' || storageSearch==null) ? '' : storageSearch);
    function setSearchText(text){
        setSearch(text);
        localStorage.setItem('search', text);
    }

    const [menuMobile, setMenuMobile] = useState(false);
    const [userMobile, setUserMobile] = useState(false);

    async function onSearch(e){
        e.preventDefault();
        history.push(`/search?q=${search}`);
    }

    function logout(){
        signOut();
        closeMenus();
        history.push('/login');
    }

    function openMenuMobile(){
        setMenuMobile(true);
    }

    function openUserMobile(){
        setUserMobile(true);
    }

    function closeMenus(){
        setMenuMobile(false);
        setUserMobile(false);
    }

    return (
        <>
            <div className={`${menuMobile ? 'menu-mobile opened' : 'menu-mobile'}`}>
                <Link onClick={closeMenus} className="menu-close">
                    <VscClose color="#6d87a0" size={34} />
                </Link>
                <ul className="menu-option-mobile">
                    <li>
                        <a href="https://www.centroculturalbrasilusa.org" target="_blank"><img style={{width: '90%', marginBottom: '10px'}} src={s_logo_1} /></a>
                    </li>
                    <li>
                        <NavLink onClick={closeMenus} to="/">Home</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={closeMenus} to="/page/about">{language.menu_about}</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={closeMenus} to="/page/terms">{language.menu_terms} & {language.menu_privacy}</NavLink>
                    </li>
                    <li>
                        <NavLink onClick={closeMenus} to="/contact">{language.menu_contact}</NavLink>
                    </li>

                    <li className="li-divider"></li>

                    <li>
                        <a onClick={() => {setLanguage('pt')}}>
                            {language.language_pt_inverse}
                        </a>
                    </li>
                    <li>
                        <a onClick={() => {setLanguage('en')}}>
                            {language.language_en_inverse}
                        </a>
                    </li>
                </ul>
            </div>
            <div className={`${userMobile ? 'menu-mobile opened' : 'menu-mobile'}`}>
                <Link onClick={closeMenus} className="menu-close">
                    <VscClose color="#6d87a0" size={34} />
                </Link>
                <ul className="menu-option-mobile">
                    <li>
                        <a href="https://www.instagram.com/fli_miami/" target="_blank" to="/account" className="user-account">
                            <FaInstagram color="#6d87a0" size={26} /> Facebook
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/Centro-Cultural-Brasil-USA-CCBU-82435213568/" target="_blank" to="/account" className="user-account">
                            <FaFacebook color="#6d87a0" size={26} /> Instagram
                        </a>
                    </li>
                    <li>
                        <Link onClick={closeMenus} to="/account" className="user-account">
                            <Icon name="UserCircle" m={{ l: '15px' }} color="#6d87a0" size="28px" /> {language.menu_account}
                        </Link>
                    </li>
                    <li>
                        <a onClick={logout} className="user-account">
                            <Icon name="Power" m={{ l: '15px' }} color="#6d87a0" size="28px" /> {language.menu_logout}
                        </a>
                    </li>
                </ul>
            </div>
            <div className={`${isHome ? 'container-full': 'container-full bg-miami' }`}>
                <Row w="100%" overflow="hidden" className="row-8">
                    <img className="img-top" src={imgTop} />
                    
                    <Col className="mobile-header mobile-view-d" size={{ md: 12 }} align="center" justify="space-between">
                        <Link onClick={openMenuMobile} className="user-account menu-action">
                            <Icon name="Menu" m={{ l: '15px' }} color="white" size="32px" />
                        </Link>
                        <Link to="/"><img className="logo" src={logo} /></Link>
                        {signed ?
                        <Link onClick={openUserMobile} className="user-account">
                            <Icon name="UserCircle" m={{ r: '15px' }} color="white" size="32px" />
                        </Link>:
                        <Link to="/login?redirect=/account" className="user-account">
                            <Icon name="UserCircle" m={{ r: '15px' }} color="white" size="32px" />
                        </Link>
                        }
                    </Col>

                    <Col className="header mobile-hidden" size={{ md: 12 }} d="flex" align="center" justify="space-between">
                        <Div p={{ l: '10px' }} d="flex" align="center" justify="flex-start">
                            <Div textTransform="uppercase" d="flex" align="center" className="main-menu">
                                <Link className="user-account menu-action">
                                    <Icon name="Menu" m={{ l: '15px' }} color="white" size="32px" />
                                </Link>
                                <ul className="menu-option">
                                    <li>
                                        <a href="https://www.centroculturalbrasilusa.org" target="_blank"><img style={{width: '220px', marginBottom: '10px'}} src={s_logo_1} /></a>
                                    </li>
                                    <li>
                                        <NavLink to="/page/about">{language.menu_about}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/page/terms">{language.menu_terms} & {language.menu_privacy}</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact">{language.menu_contact}</NavLink>
                                    </li>
                                </ul>
                            </Div>
                            <Link to="/"><img className="logo" src={logo} /></Link>
                        </Div>
                        <Div p={{ r: '30px' }} d="flex" align="center" justify="flex-end">
                            <a href="https://www.instagram.com/fli_miami/" target="_blank" to="/account" className="user-account mr15">
                                <FaInstagram color="#ffffff" size={26} />
                            </a>
                            <a href="https://www.facebook.com/Centro-Cultural-Brasil-USA-CCBU-82435213568/" target="_blank" to="/account" className="user-account mr15">
                                <FaFacebook color="#ffffff" size={26} />
                            </a>
                            <form onSubmit={onSearch}>
                                <Input
                                    className="search"
                                    placeholder={language.menu_search}
                                    value={search}
                                    onChange={e => setSearchText(e.target.value)}
                                    border="0"
                                    rounded="circle"
                                    p={{ l: '20px', r: '40px', t: '10px', b: '10px' }}
                                    suffix={
                                        <Icon
                                            name="Search"
                                            size="20px"
                                            cursor="pointer"
                                            onClick={onSearch}
                                            onSubmit={onSearch}
                                            pos="absolute"
                                            top="50%"
                                            right="1rem"
                                            transform="translateY(-50%)"
                                        />
                                    }
                                />
                            </form>
                            <div className="lang-content">
                                <a onClick={() =>{}} className="lang-menu">
                                    <div>{language.language_sigla}</div>
                                    <div className="lang-arrow"><HiChevronDown size={14} /></div>
                                </a>
                                <ul className="lang-option">
                                    <li>
                                        <a onClick={() => {setLanguage('pt')}}>
                                            {language.language_pt_inverse}
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => {setLanguage('en')}}>
                                            {language.language_en_inverse}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {signed ?
                            <>
                                <Link to="/account" className="user-account">
                                    {image==='' ?
                                    <Icon name="UserCircle" m={{ l: '15px' }} color="white" size="32px" />:
                                    <Image w="32px" m={{ l: '15px' }} shadow="2" rounded="circle" src={Base} className="cover-bg" style={{backgroundImage: `url(${image})`}} />
                                    }
                                </Link>
                                <a onClick={logout} className="user-account">
                                    <Icon name="Power" m={{ l: '15px' }} color="white" size="32px" />
                                </a>
                            </>:
                            <>
                                <Link to="/login?redirect=/account" className="ml20 link">
                                    LOGIN
                                </Link>
                            </>
                            }
                        </Div>
                       
                    </Col>
                </Row>
                
                <HeaderCarousel lang={language.language} content={content} isHome={isHome} isSearch={isSearch} />
                
            </div>
            
        </>
    );
}