import React, { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Input, Button, Icon, Notification } from "atomize";

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import AuthContext from '../../contexts/auth';
import LanguageContext from '../../contexts/language';

export default function Login() {

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    function setViewPassword(){
        showPassword ? setShowPassword(false) : setShowPassword(true);
    }
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    
    const { signIn } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);

    //var userLang = navigator.language || navigator.userLanguage; 
    //alert ("The language is: " + userLang);

    async function submitLogin(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(email=="" || password==""){
                setLoading(false);
                setError(true);
                setErrorMessage(language.no_data_login);
                return;
            }
            const response = await signIn(
                email,
                password,
                remember
            );

            if (!response.user) {
                setError(true);
                setErrorMessage(language.login_error);
                return;
            }

            setLoading(false);
            setError(false);

            if(query.get('redirect')==null || query.get('redirect')=='null' || query.get('redirect')==''){
                history.push('/');
            }else{
                history.push(query.get('redirect'));
            }

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(language.error);

        }
    }

    function checkToogle(){
        remember ? setRemember(false) : setRemember(true);
    }

    return (
        <div className="custom-page">
            <Header />
            <Notification
                bg="danger700"
                isOpen={error}
                onClose={() => setError(false)}
                shadow="3"
                textTransform="uppercase"
            >
            {error_message}
            </Notification>
            <Div bg="gray300" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col p="30px" size={{ md: 4 }} bg="white" shadow="3" rounded="lg" className="m-auto" textAlign="center">
                            <form onSubmit={submitLogin}>
                                <Div p={{ t: '10px', b: '30px' }}>
                                    <Text p={{ b: '10px' }} textSize="title" textColor="black800">
                                        {language.form_login}
                                    </Text>
                                    <Text textSize="body" textColor="gray700">
                                        {language.no_account} <Link to={`/create-account?redirect=${query.get('redirect')}`} className="b-link">{language.create_account}</Link>
                                    </Text>
                                </Div>
                                <Div p={{ b: '30px' }}>
                                    <Input
                                        placeholder={language.form_email}
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        focusBorderColor="info600"
                                        rounded="lg"
                                        m={{ b: '20px' }}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                        suffix={
                                        <Icon
                                            name="Email"
                                            color="gray900"
                                            size="16px"
                                            pos="absolute"
                                            top="50%"
                                            right="1rem"
                                            transform="translateY(-50%)"
                                        />
                                        }
                                    />

                                    <Input
                                        placeholder={language.password}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        focusBorderColor="info600"
                                        rounded="lg"
                                        type={showPassword ? "text" : "password"}
                                        p={{ l: '12px', r: '40px', t: '12px', b: '10px' }}
                                        suffix={
                                        <Button
                                            type="button"
                                            pos="absolute"
                                            onClick={setViewPassword}
                                            bg="transparent"
                                            w="3rem"
                                            top="0"
                                            right="0"
                                        >
                                            <Icon
                                            name={showPassword ? "EyeSolid" : "Eye"}
                                            color="gray900"
                                            size="16px"
                                            pos="absolute"
                                            top="50%"
                                            right="1rem"
                                            transform="translateY(-50%)"
                                            />
                                        </Button>
                                        }
                                    />
                                    <Div textSize="caption" textAlign="right">
                                        <Link to="/forgot" className="b-link">{language.forgot_password}</Link>
                                    </Div>
                                </Div>

                                <Button
                                    type="submit"
                                    w="100%"
                                    h="44px"
                                    textWeight="600"
                                    textSize="body"
                                    textColor="info700"
                                    hoverTextColor="info900"
                                    bg="info200"
                                    hoverBg="info300"
                                    rounded="lg"
                                    disabled={loading}
                                >
                                    {
                                    loading ?
                                    <><Icon
                                        name="Loading"
                                        size="22px"
                                        color="info700"
                                        m={{r: '6px'}}
                                    /> {language.form_loging}</>:
                                    <>Login</>
                                    }
                                </Button>
                                
                            </form>
                        </Col>
                    </Row>
                </Container>
            </Div>

            <Footer />
        </div>
        
    );
}