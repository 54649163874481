import React, { useContext, useState, useEffect } from 'react';
import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import News from '../../components/News';
import ShimmerNews from '../../components/ShimmerNews';

import LanguageContext from '../../contexts/language';

export default function PageNews() {

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    async function loadPage(){
 
        const response = await api.post(`/news?lang=${lang}`);
        setNews(response.data.content.news ? response.data.content.news: []);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPage();
    }, [language]);

    return (
        <div className="content-app">
            <Header />
            {loading ?
            <ShimmerNews />:
            <News content={news} />
            }
            <Footer />
        </div>
    );
}
