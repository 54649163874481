import React from 'react';
import { Div, Text, Container, Row, Col } from "atomize";

import Base from '../assets/images/blank-850.png';
import Shimmer from './Shimmer';

export default function ShimmerNews(props) {

    const { isHome } = props;

    return (
        <Div bg="gray200" p={{ t: isHome ? '40px' : { xs: '120px', md: '200px' }, b: '40px' }}>
            <Container>
                <Row>
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '40px' }} textSize="display1" textColor="black800" textAlign="center"><Shimmer width={250} height={30} /></Text>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col p="20px" size={{ xs: 12, md: 4 }}>
                        <div className="no-underline">
                            <Div shadow="2" rounded="8px">
                                <Shimmer source={Base} rounded={0} />
                                <Div rounded={{ b: "8px" }} bg="white" p="10px">
                                    <Text textSize="subheader" textColor="black600" textAlign="left"><Shimmer /></Text>
                                    <Text textSize="paragraph" textColor="gray800" textAlign="left"><Shimmer width={200} /><br /><Shimmer width={160} /></Text>
                                </Div>
                            </Div>
                        </div>
                    </Col>
                    <Col p="20px" size={{ xs: 12, md: 4 }}>
                        <div className="no-underline">
                            <Div shadow="2" rounded="8px">
                                <Shimmer source={Base} rounded={0} />
                                <Div rounded={{ b: "8px" }} bg="white" p="10px">
                                    <Text textSize="subheader" textColor="black600" textAlign="left"><Shimmer /></Text>
                                    <Text textSize="paragraph" textColor="gray800" textAlign="left"><Shimmer width={200} /><br /><Shimmer width={160} /></Text>
                                </Div>
                            </Div>
                        </div>
                    </Col>
                    <Col p="20px" size={{ xs: 12, md: 4 }}>
                        <div className="no-underline">
                            <Div shadow="2" rounded="8px">
                                <Shimmer source={Base} rounded={0} />
                                <Div rounded={{ b: "8px" }} bg="white" p="10px">
                                    <Text textSize="subheader" textColor="black600" textAlign="left"><Shimmer /></Text>
                                    <Text textSize="paragraph" textColor="gray800" textAlign="left"><Shimmer width={200} /><br /><Shimmer width={160} /></Text>
                                </Div>
                            </Div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Div>
    );
}