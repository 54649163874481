import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';
import { Container, Row, Col } from "atomize";

import Base from '../assets/images/base-banner.png';
import BaseSquare from '../assets/images/blank-600.png';

import home_author from '../assets/images/home_author.jpg';
import home_books from '../assets/images/home_books.jpg';
import home_schedule from '../assets/images/home_schedule.jpg';
import home_welcome from '../assets/images/home_welcome.jpg';
import home_events from '../assets/images/home_events.jpg';

import LanguageContext from '../contexts/language';

export default function HeaderCarousel(props) {

    const { language } = useContext(LanguageContext);
    const { content, lang, isHome, isSearch } = props;

    function Menu(props){

        const { title, image, target } = props;

        return (
            <>
                <Col className="mobile-hidden">
                    <Link to={`/${target}`} className="thumb-movie__actions mb20">
                        <div className="thumb-movie__img">
                            <div className="thumb-movie__play">{title}</div>
                            <img src={BaseSquare} className="cover-bg" style={{backgroundImage: `url(${image})`}} />
                        </div>
                    </Link>
                </Col>
                <Col size={{ xs: 4 }} className="mobile-view-d">
                    <Link to={`/${target}`} className="thumb-movie__actions mb20">
                        <div className="thumb-movie__img">
                            <div className="thumb-movie__play">{title}</div>
                            <img src={BaseSquare} className="cover-bg" style={{backgroundImage: `url(${image})`}} />
                        </div>
                    </Link>
                </Col>
            </>
        )
    }


    return (
        <>
            {isHome &&
            <Carousel
                className="carousel-banner-home"
                interval={5000}
                transitionTime={500}
                autoPlay={true}
                infiniteLoop={true}
                showArrows={true}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                stopOnHover={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <a onClick={onClickHandler} title={label} className="btn-prev">
                            <FiChevronLeft size={34} />
                        </a>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <a onClick={onClickHandler} title={label} className="btn-next">
                            <FiChevronRight size={34} />
                        </a>
                    )
                }
                >
                {content.map(item => (
                <div key={item.id}>
                    {item.id=="ccbu" ? 
                    <a href="https://www.centroculturalbrasilusa.org" style={{display: 'block'}}>
                        <img src={Base} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                    </a>:
                    <Link to={`/event/${item.id}`} style={{display: 'block'}}>
                        <img src={Base} className="cover-bg" style={{backgroundImage: `url(${item.image})`}} />
                    </Link>
                    }
                </div>
                ))}
            </Carousel>
            }
            <div className={`${isHome ? 'thumb-movie' : 'thumb-movie-single'}`}>
                <Container>
                    <Row justify="center">
                        <Menu image={home_welcome} title={language.menu_welcome} target="welcome" />
                        <Menu image={home_schedule} title={language.menu_schedule} target="page/schedule" />
                        <Menu image={home_events} title={language.title_event} target="events" />
                        <Menu image={home_author} title={language.menu_authors} target="authors" />
                        <Menu image={home_books} title={language.menu_library} target="books" />
                    </Row>
                </Container>
            </div>
        </>
    );
}