import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Div, Container, Row, Col, Text, Image } from "atomize";
import { BiChevronRight } from "react-icons/bi";

import LanguageContext from '../contexts/language';
import Base from '../assets/images/blank-br.png';
import Livro from '../assets/images/brazilinmiami.jpg';

export default function BookFeatured(props) {

    const { isHome } = props;

    const { language } = useContext(LanguageContext);

    return (
        <Div bg="white" p={{ t: isHome ? '60px' : { xs: '120px', md: '200px' }, b: '60px' }}>
            <Container>
                <Row>
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '40px' }} textSize="display1" textColor="black800" textAlign="center">{language.title_bookfeatured}</Text>
                    </Col>
                </Row>
                <Row d="flex" justify="center">
                    <Col size={{ md: 8 }}>
                        <Link to={`/brazil-in-miami`}>
                            <Image shadow="3" rounded="12px" src={Base} className="cover-bg" style={{backgroundImage: `url(${Livro})`}} />
                        </Link>
                    </Col>
                </Row>
                <Row>
                    <Col size={{ md: 'auto' }} p={{ t: "80px", b: "40px"}} textAlign="center">
                        <Link to={`/brazil-in-miami`} className="btn_all">
                            {language.more} <BiChevronRight size={22} style={{marginLeft: '6px'}} />
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Div>
    );
}