import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, Container, Row, Col } from "atomize";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LanguageContext from '../../contexts/language';

export default function Page404() {

    const { language } = useContext(LanguageContext);

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '40px' }} textSize="display2" textColor="black800">{language.title_404}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col p="20px" size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text textSize="paragraph" textColor="gray800">{language.content_404}</Text>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col size={{ md: 8 }} p={{ t: "40px", b: "40px"}} className="m-auto" textAlign="center">
                            <Link to="/" className="btn_all">
                                {language.btn_404}
                            </Link>
                        </Col>
                    </Row>
                   
                </Container>
            </Div>
            <Footer />
        </>
        
    );
}