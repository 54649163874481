import React from 'react';
import { Container, Row, Col } from "atomize";
import Base from '../assets/images/base-banner.png';
import BaseSquare from '../assets/images/blank-600.png';
import Shimmer from './Shimmer';

export default function ShimmerHeaderCarousel() {

    function Menu(){

        return (
            <>
                <Col className="mobile-hidden">
                    <div className="thumb-movie__actions mb20">
                        <div className="thumb-movie__img">
                            <Shimmer source={BaseSquare} rounded={400}  />
                        </div>
                    </div>
                </Col>
                <Col size={{ xs: 4 }} className="mobile-view-d">
                    <div className="thumb-movie__actions mb20">
                        <div className="thumb-movie__img">
                            <Shimmer source={BaseSquare} rounded={400}  />
                        </div>
                    </div>
                </Col>
            </>
        )
    }

    return (
        <>
            <div className="carousel-banner-home">
                <Shimmer source={Base} rounded={0}  />
            </div>
            
            <div className='thumb-movie'>
                <Container>
                    <Row justify="center">
                        <Menu />
                        <Menu />
                        <Menu />
                        <Menu />
                        <Menu />
                    </Row>
                </Container>
            </div>
        </>
    );
}