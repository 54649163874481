import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image } from "atomize";
import { BiChevronRight } from "react-icons/bi";

import LanguageContext from '../contexts/language';

export default function News(props) {

    const { language } = useContext(LanguageContext);

    const { content, isHome } = props;
    
    return (
        <Div bg="gray200" p={{ t: isHome ? '60px' : { xs: '120px', md: '200px' }, b: '60px' }}>
            <Container>
                <Row>
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '40px' }} textSize="display1" textColor="black800" textAlign="center">{language.title_news}</Text>
                    </Col>
                </Row>
                <Row>
                    {content.map(item => (
                    <Col key={item.id} p="20px" size={{ xs: 12, md: 4 }}>
                        <Link to={`/post/${item.url}`} className="no-underline menu-opacity">
                            <Div shadow="2" hoverShadow="3" rounded="8px" className="menu-zoom">
                                <Image rounded={{ t: "8px" }} src={item.image} />
                                <Div rounded={{ b: "8px" }} bg="white" p="10px">
                                    <Text textSize="subheader" textColor="black600" textAlign="left">{item.title}</Text>
                                    <Text textSize="paragraph" textColor="gray800" textAlign="left">{item.text}</Text>
                                </Div>
                            </Div>
                        </Link>
                    </Col>
                    ))}
                </Row>
                {isHome &&
                <Row>
                    <Col size={{ md: 'auto' }} p={{ t: "40px", b: "40px"}} textAlign="center">
                        <Link to={`/news`} className="btn_all">
                            {language.btn_all} <BiChevronRight size={22} style={{marginLeft: '6px'}} />
                        </Link>
                    </Col>
                </Row>
                }
            </Container>
        </Div>
    );
}