import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Image } from "atomize";

import api from '../services/api';

import AuthContext from '../contexts/auth';
import LanguageContext from '../contexts/language';

export default function PurchasesList() {
    
    const { signed, user, token } = useContext(AuthContext);
    const { id } = useParams();

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(!signed){
            history.push(`/login?redirect=${location.pathname}`);
        }else{
            setLoading(true);
            loadPage();
        }
    }, [user]);

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [purchases, setPurchases] = useState([]);

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    async function loadPage(){
       
        const response = await api.post('/purchases', {}, config);
        setPurchases(response.data.shopping);
        setLoading(false);
    }

    return (
        <Div bg="gray300" p={{ t: { xs: '30px', md: '60px' }, b: '60px' }}>
                <Container>
                    <Row>
                        <Col size={{ md: 8 }} className="m-auto" textAlign="center">
                            <Text p={{ b: '40px' }} textSize="display2" textColor="black800">{language.menu_purchases}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col p="30px" size={{ md: 8 }} bg="white" shadow="3" rounded="lg" className="m-auto" textAlign="center">
                            <Div d="flex" flexDir="column" align="center" justify="center">
                            {purchases.length==0 ?
                                <div className="m-auto">
                                    <div className="content-error mb10">
                                        {lang==='pt' ? "Nenhuma Compra Encontrada!": "No Purchase Found!" }
                                    </div>
                                </div>:
                                <Container>
                                    <Row>
                                        <Col size="5" textAlign="left">
                                            ID
                                        </Col>
                                        <Col size="5" textAlign="left">
                                            STATUS
                                        </Col>
                                        <Col size="2" textAlign="right">
                                            TOTAL
                                        </Col>
                                    </Row>

                                    {purchases.map(item => (
                                    <Row key={item.payment_id}>
                                        <Col size="5" textAlign="left">
                                            <Link to={`/purchase/${item.payment_id}`}>{item.payment_id}</Link>
                                        </Col>
                                        <Col size="5" textAlign="left">
                                        {
                                        item.payment_type=="paypal" && item.payment_status=="CREATED" ?
                                            <>
                                                <p className="text-success text-bold mb-0">
                                                    <a href={item.payment_url}>
                                                        {lang==='pt' ? "IR PARA O PAGAMENTO": "CONTINUE TO PAYMENT" }
                                                    </a>
                                                </p>
                                            </>:
                                            <>
                                            {
                                            item.payment_status=="paid" || item.payment_status=="3" ?
                                            <p className="text-success text-bold mb-0">
                                                {lang==='pt' ? "PAGO COM SUCESSO": "SUCCESSFUL PAY" }
                                            </p>:
                                            <p className="text-warning text-bold mb-0">
                                                {lang==='pt' ? "PROCESSANDO PAGAMENTO": "PROCESSING PAYMENT " }
                                            </p>
                                            }
                                            </>
                                        }
                                        </Col>
                                        <Col size="2" textAlign="right">
                                            <Text textColor="success800">{Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(item.price)}</Text>
                                        </Col>
                                    </Row>
                                    ))}
                                </Container>
                                }
                            </Div>
                        </Col>
                    </Row>
                </Container>
            </Div>
    );
}