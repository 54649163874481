import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { Div, Text, Container, Row, Col, Input, Button, Icon, Notification } from "atomize";
import { ImCreditCard, ImPaypal } from "react-icons/im";
import { BsExclamationCircle } from "react-icons/bs";
import { IoMdSquareOutline, IoIosCheckbox } from "react-icons/io";

import api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import AuthContext from '../../contexts/auth';
import LanguageContext from '../../contexts/language';

export default function PaymentCapture() {

    const { signed, signIn, token, signOut } = useContext(AuthContext);
    const { id } = useParams();
    const history = useHistory();
    const region = localStorage.getItem('region');

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');

    const config = {
        headers: {
            'Authorization':`Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }
    };

    async function capturePayment(){
        try{
            const response = await api.post(
                `/capture?paypal_id=${query.get("token")}`
            , {}, config);

            if(response.data.status=="error"){
                setError(true);
                setLoading(false);
                setErrorMessage(response.data.message);
                return;
            }else if(response.data.status=="error_paypal"){
                setError(true);
                setLoading(false);
                setErrorMessage(response.data.message.message);
                return;
            }

            if(response.data.payment_status=="pending"){
                window.location.href = `/payment-process/${response.data.payment_id}`;
            }else{
                window.location.href = `/payment-success/${response.data.payment_id}`;
            }
            setError(false);
            setLoading(true);
            
        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage(err.message);
        }
    }

    useEffect(() => {
        capturePayment();
    }, []);
    

    return (
        <>
            <Header />
            <Div bg="gray300" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col p="30px" size={{ md: 4 }} bg="white" shadow="3" rounded="lg" textAlign="center" className="m-auto">
                            <Div p={{ t: '30px', b: '30px' }} d="flex" align="center" justify="center">
                                <Text textSize="title" textColor="black800">
                                {
                                    loading &&
                                    <div className="loader-btn mt-0 ml-0">
                                        <div className="spinner-content"><div className="bt-spinner mini"></div></div>
                                        <div className="spinner-text">{language.form_capture}</div>
                                    </div>
                                }
                                {
                                    error &&
                                    <div className="m-auto">
                                        <div className="content-error mb10">
                                            <BsExclamationCircle size={18} /> {error_message}
                                        </div>
                                        <Link to="/purchases" className="btn_all mt20">{language.btn_error}</Link>
                                    </div>
                                }
                                </Text>
                            </Div>
                        </Col>
                    </Row>
                </Container>
            </Div>

            <Footer />
        </>
        
    );
}