import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { Div, Text, Container, Row, Col, Icon, Image } from "atomize";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PurchasesList from '../../components/PurchasesList';

import AuthContext from '../../contexts/auth';
import LanguageContext from '../../contexts/language';
import Base from '../../assets/images/blank-600.png';

import image_default from '../../assets/images/default.png';

export default function Account() {

    const { signed, user, token } = useContext(AuthContext);
    const { language } = useContext(LanguageContext);
    const lang = localStorage.getItem('language');

    const [content, setContent] = useState({});
    const [image, setImage] = useState(image_default);
    const inputFileRef = useRef(null);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(!signed){
            history.push(`/login?redirect=${location.pathname}`);
        }else{
            loadUser();
        }
    }, [user]);

    function selectFile(){
        inputFileRef.current.click();
    }

    function loadUser() {
        setContent(user);
        setImage(user.image==='' ? image_default : user.image);
    }

    async function onFile(e) {

        var file = e.target.files.item(0);
        var reader = new FileReader();
        reader.onloadend = function() {
            setImage(reader.result);
            onUpload(reader.result);
        }
        reader.readAsDataURL(file);
    
    }

    async function onUpload(image) {

        const response = await api.post(
            `/upload-image?id_image=${user.id_image}`
        , {image});

        const new_content_user = {
            name: user.name,
            type: user.type,
            id_image: user.id_image,
            image: response.data.file,
            email: user.email,
        }
        localStorage.setItem('user', JSON.stringify(new_content_user));
    
    }

    return (
        <>
            <Header />
            <Div bg="white" p={{ t: { xs: '120px', md: '200px' }, b: '100px' }}>
                <Container>
                    <Row>
                        <Col size={{ xs: 6, md: 2 }} className="m-auto" textAlign="center">
                            <Div pos="relative">
                            {
                            content &&
                            <>  
                                <a onClick={selectFile} style={{cursor: 'pointer'}}>
                                    <div className="cam-photo">
                                        <Icon name="CameraSolid" color="black400" size="24px" />
                                        <Text textSize="body" textColor="black400">{language.change_photo}</Text>
                                    </div>
                                    <Image shadow="2" rounded="circle" src={Base} className="cover-bg" style={{backgroundImage: `url(${image})`}} />
                                </a>
                                <input style={{display:'none'}} ref={inputFileRef} type="file" id="photo-file" onChange={onFile} />
                            </>
                            }
                            </Div>
                        </Col>
                    </Row>
                    <Row>
                        <Col size={{ xs: 12, md: 3 }} className="m-auto" p={{t:"10px"}} textAlign="center">
                            <Text textColor="black800" textSize="display1">{content.name}</Text>
                            <Text m={{t: '15px'}} textColor="gray600" textSize="body">{content.email}</Text>
                        </Col>
                    </Row>
                </Container>
            </Div>
            <PurchasesList />
            <Footer />
        </>
        
    );
}