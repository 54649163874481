import React from 'react';
import { Div, Text, Container, Row, Col } from "atomize";

import Base from '../assets/images/blank-600.png';
import Shimmer from './Shimmer';

export default function ShimmerAuthors(props) {

    const { isHome } = props;

    return (
        <Div bg="white" p={{ t: isHome ? '40px' : { xs: '120px', md: '200px' }, b: '40px' }}>
            <Container>
                <Row>
                    <Col size={{ md: 12 }}>
                        <Text p={{ b: '40px' }} textSize="display1" textColor="black800" textAlign="center"><Shimmer width={250} height={30} /></Text>
                    </Col>
                </Row>
                <Row>
                    <Col p="20px" size={{ xs: 6, md: 4 }}>
                        <div className="no-underline">
                            <Shimmer source={Base} rounded={12} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center"><Shimmer width={120} /></Text>
                        </div>
                    </Col>
                    <Col p="20px" size={{ xs: 6, md: 4 }}>
                        <div className="no-underline">
                            <Shimmer source={Base} rounded={12} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center"><Shimmer width={120} /></Text>
                        </div>
                    </Col>
                    <Col p="20px" size={{ xs: 6, md: 4 }} className="mobile-hidden">
                        <div className="no-underline">
                            <Shimmer source={Base} rounded={12} />
                            <Text p={{ t: '10px' }} textSize="title" textColor="black600" textAlign="center"><Shimmer width={120} /></Text>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Div>
    );
}